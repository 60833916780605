import React from 'react';
import { hectares } from '../shared';

export default function FormattedNumber({ prefix, suffix, value, minimumFractionDigits, maximumFractionDigits }) {
  if (value === null || value === undefined) {
    return '-';
  }

  return (
    <React.Fragment>
      {prefix} {hectares(value, minimumFractionDigits, maximumFractionDigits)} {suffix}
    </React.Fragment>
  );
}
