import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { AppBar, Button, Toolbar, IconButton, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import styles from './styles';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';
import netseedsLogo from '../../images/logo-bar.png';

class PrimarySearchAppBar extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleLogOut = () => {
    this.handleMenuClose();
    this.props.onLogOut();
    this.props.history.push('/sair');
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  render() {
    const { anchorEl, mobileMoreAnchorEl } = this.state;
    const { classes, loading, user } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    return (
      <AppBar position="sticky">
        {loading && (
          <LinearProgress
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary,
            }}
          />
        )}
        {!loading && (
          <LinearProgress
            variant="determinate"
            classes={{
              colorPrimary: classes.linearColorPrimary,
              barColorPrimary: classes.linearBarColorPrimary,
            }}
            value={0}
          />
        )}
        <Toolbar
          classes={{
            root: classes.toolbarRoot,
            gutters: classes.toolbarGutters,
          }}
        >
          <Link to="/">
            <img alt="NetSeeds" src={netseedsLogo} />
          </Link>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button
              component={NavLink}
              to="/cultivares"
              activeClassName={classes.activeLink}
              color="inherit"
              className={classes.button}
            >
              Cultivares
            </Button>
            <Button
              component={NavLink}
              to="/licenciamento"
              activeClassName={classes.activeLink}
              color="inherit"
              className={classes.button}
            >
              Licenciamento
            </Button>
            {user && (user.isLicenser || user.isBreeder || user.isBreederViewer) && (
              <Button
                component={NavLink}
                to="/produtores"
                activeClassName={classes.activeLink}
                color="inherit"
                className={classes.button}
              >
                Produtores
              </Button>
            )}
            {user && (
              <React.Fragment>
                <Button
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleProfileMenuOpen}
                  color="inherit"
                  variant="outlined"
                  className={classes.button}
                  data-test="logged-in-user-button"
                >
                  <AccountCircle className={classes.leftIcon} />
                  {user.name}
                </Button>
              </React.Fragment>
            )}
            {!user && (
              <Button color="inherit" component={Link} to="/entrar" variant="outlined" className={classes.button}>
                Entrar
              </Button>
            )}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
        {user && <DesktopMenu anchorEl={anchorEl} onClose={this.handleMenuClose} open={isMenuOpen} user={user} />}
        <MobileMenu
          anchorEl={mobileMoreAnchorEl}
          classes={classes}
          onClose={this.handleMobileMenuClose}
          onProfileClick={this.handleProfileMenuOpen}
          open={isMobileMenuOpen}
          user={user}
        />
      </AppBar>
    );
  }
}

PrimarySearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onLogOut: PropTypes.func.isRequired,
  user: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(PrimarySearchAppBar);
