import React from 'react';
import DownloadAttachmentLink from './DownloadAttachmentLink';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormattedNumber from './FormattedNumber';

export default function EventMessage({ active, event, includeDetails = false, process }) {
  const messages = {
    REQUEST_TO_PRODUCE_SENT: 'Solicitação para produzir enviada',
    REQUEST_TO_PRODUCE_SENT_ACTIVE: 'enviou solicitação para produzir',
    REQUEST_TO_PRODUCE_APPROVED: 'Solicitação para produzir aceita',
    REQUEST_TO_PRODUCE_APPROVED_ACTIVE: 'aceitou a solicitação para produzir',
    REQUEST_TO_PRODUCE_REJECTED: 'Solicitação para produzir rejeitada',
    REQUEST_TO_PRODUCE_REJECTED_ACTIVE: 'rejeitou a solicitação para produzir',
    CONTRACT_ISSUED: 'Contrato emitido',
    CONTRACT_ISSUED_ACTIVE: (
      <>
        emitiu o{' '}
        <DownloadAttachmentLink id={event.attachment && event.attachment.id} process={process}>
          contrato
        </DownloadAttachmentLink>
      </>
    ),
    CONTRACT_SIGNED: 'Contrato assinado',
    CONTRACT_SIGNED_ACTIVE: (
      <>
        assinou o{' '}
        <DownloadAttachmentLink id={event.attachment && event.attachment.id} process={process}>
          contrato
        </DownloadAttachmentLink>
      </>
    ),
    CONTRACT_REJECTED: 'Contrato rejeitado',
    CONTRACT_REJECTED_ACTIVE: (
      <>
        rejeitou o{' '}
        <DownloadAttachmentLink id={event.attachment && event.attachment.id} process={process}>
          contrato
        </DownloadAttachmentLink>
      </>
    ),
    FIELD_REGISTRATION_AUTHORIZATION_ISSUED: 'Autorização para inscrição de campo emitida',
    FIELD_REGISTRATION_AUTHORIZATION_ISSUED_ACTIVE: (
      <>
        emitiu a{' '}
        <DownloadAttachmentLink id={event.attachment && event.attachment.id} process={process}>
          autorização
        </DownloadAttachmentLink>{' '}
        para inscrição de campo
      </>
    ),
    FIELD_REGISTRATION_CONFIRMATION_SENT: 'Número da inscrição de campo enviada',
    FIELD_REGISTRATION_CONFIRMATION_SENT_ACTIVE: (
      <>
        enviou o número{' '}
        <DownloadAttachmentLink id={event.attachment && event.attachment.id} process={process}>
          {event.data.fieldRegistrationId}
        </DownloadAttachmentLink>{' '}
        de inscrição de campo
      </>
    ),
    YIELD_INFORMATION_UPDATED: 'Informação de produção atualizada',
    YIELD_INFORMATION_UPDATED_ACTIVE: 'atualizou a informação de produção',
    PROCESS_IMPORTED: 'Informação do processo importada',
    PROCESS_IMPORTED_ACTIVE: 'importou a informação do processo',
    MESSAGE_POSTED: 'Mensagem enviada',
    MESSAGE_POSTED_ACTIVE: 'enviou uma mensagem',
    PROCESS_CONSOLIDATED: 'Processo consolidado',
    PROCESS_CONSOLIDATED_ACTIVE: 'consolidou o processo',
    PROCESS_CANCELED: 'Processo cancelado',
    PROCESS_CANCELED_ACTIVE: (
      <>
        cancelou o processo, com a seguinte justificativa: <em>{event.data.message}</em>
      </>
    ),
    REMINDER_TRIGGERED: 'Lembrete para envio de informações disparado',
    REMINDER_TRIGGERED_ACTIVE: 'disparou um lembrete para envio de informações',
  };

  const messagesWithDetails = {
    YIELD_INFORMATION_UPDATED_ACTIVE: (
      <>
        atualizou a informação de produção:
        <Grid container>
          <Grid item xs={5}>
            Área aprovada
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.approvedArea} suffix=" ha" />
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={5}>
            Área homologada
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.homologatedArea} suffix=" ha" />
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={5}>
            Semente bruta
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.receivedSeedAmount} suffix=" t" />
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={5}>
            Semente beneficiada
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.processedSeedAmount} suffix=" t" />
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={5}>
            Semente aprovada
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.approvedSeedAmount} suffix=" t" />
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={5}>
            Semente para uso próprio
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.ownUseSeedAmount} suffix=" t" />
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={5}>
            Semente comercializada
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.commercializedSeedAmount} suffix=" t" />
            </Typography>
          </Grid>
          <Grid item xs={4} />
          <Grid item xs={5}>
            Valor de semente comercializada
          </Grid>
          <Grid item xs={3}>
            <Typography align="right">
              <FormattedNumber value={event.data.commercializedSeedValue} prefix="R$ " />
            </Typography>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </>
    ),
  };
  let eventMessage;
  if (includeDetails) {
    eventMessage = messagesWithDetails[`${event.type}${active && '_ACTIVE'}`];
  }
  if (!eventMessage) {
    eventMessage = messages[`${event.type}${active && '_ACTIVE'}`];
  }

  return <>{eventMessage}</>;
}
