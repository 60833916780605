import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core';
import FormattedNumber from '../../../components/FormattedNumber';
import { useTranslation } from 'react-i18next';

function LicensingProcessCard({ licensingProcess = {} }) {
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <CardActionArea component={Link} to={`/licenciamento/processos/${licensingProcess.id}`}>
        <Divider />
        <CardContent>
          <Grid container item spacing={1}>
            <Grid container item spacing={1} xs={2}>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  #
                </Typography>
                <Typography variant="subtitle2" color="primary">
                  {licensingProcess.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={10}>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Safra
                </Typography>
                <Typography variant="subtitle2">{licensingProcess.seasonYear}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Cultivar
                </Typography>
                <Typography variant="subtitle2">{licensingProcess.cultivarName}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Categoria
                </Typography>
                <Typography variant="subtitle2">{t(`SEED_CATEGORY_${licensingProcess.seedCategory}`)}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Área
                </Typography>
                <Typography variant="subtitle2">
                  <FormattedNumber value={licensingProcess.area} suffix="ha" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Grid>
  );
}

function LicensingProcessesCard({ licensingProcesses = [] }) {
  return (
    <Card>
      <CardHeader title="Processos de Licenciamento" />
      <Grid container>
        {licensingProcesses.map((licensingProcess) => (
          <LicensingProcessCard key={licensingProcess.id} licensingProcess={licensingProcess} />
        ))}
        {licensingProcesses.length === 0 && (
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              Este produtor não tem processos de licenciamento
            </Typography>
          </CardContent>
        )}
      </Grid>
    </Card>
  );
}

export default LicensingProcessesCard;
