import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, withStyles, CircularProgress } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import Footer from './Footer';

const styles = (theme) => ({
  '@global': {
    '#root': {
      backgroundColor: '#f6f6f6',
    },
  },
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    padding: `${theme.spacing(1)}px 0px ${theme.spacing(8)}px`,
    [theme.breakpoints.up('lg')]: {
      width: '1200px',
    },
    margin: 'auto',
    minHeight: `calc(100vh - 69px - 183px)`,
  },
  breadcrumb: {
    // color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      // color: theme.palette.primary.dark
      textDecoration: 'underline',
    },
  },
  title: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  actions: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  progress: {
    textAlign: 'center',
  },
});

const MainLayout = ({ actions, breadcrumbs = [], children, classes, nav, ready, subtitle, title }) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{`NetSeeds - ${title}`}</title>
      </Helmet>
      {nav}
      <Grid
        className={classes.layout}
        component="main"
        container
        direction="column"
        spacing={3}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <Typography variant="body2">
            {breadcrumbs.map((breadcrumb, index, array) => (
              <React.Fragment key={breadcrumb.path}>
                {index < array.length - 1 && (
                  <Link to={breadcrumb.path} className={classes.breadcrumb}>
                    {breadcrumb.label}
                  </Link>
                )}
                {index === array.length - 1 && breadcrumb.label}
                {index < array.length - 1 && ' > '}
              </React.Fragment>
            ))}
          </Typography>
        </Grid>
        <Grid container item justifyContent="space-between">
          <Grid item xs={12} sm={6} className={classes.title}>
            <Typography component="h2" variant="h4">
              {title}
            </Typography>
          </Grid>
          {ready && (
            <Grid item xs={12} sm={6} className={classes.actions}>
              {actions}
            </Grid>
          )}
        </Grid>
        {!ready && (
          <Grid item className={classes.progress}>
            <CircularProgress />
          </Grid>
        )}
        {ready && (
          <React.Fragment>
            {subtitle && (
              <Grid item>
                <Typography variant="body1">{subtitle}</Typography>
              </Grid>
            )}
            <Grid container item>
              {children}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <Footer />
    </React.Fragment>
  );
};

export default withStyles(styles)(MainLayout);
