import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EventMessage from '../../components/EventMessage';
import DownloadAttachmentLink from '../../components/DownloadAttachmentLink';
import FormattedDate from '../../components/FormattedDate';
import SeedCategory from '../../components/SeedCategory';

export default function RequestPhaseResult({ process, t, user }) {
  if (process.isBeyondRequestPhase()) {
    const requestEvent = process.getRequestEvent();
    const responseEvent = process.getRespondToRequestEvent();
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom>
            {user.email === requestEvent.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{requestEvent.user}</strong> da <strong>{requestEvent.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={requestEvent} process={process} /> em{' '}
            <strong>
              <FormattedDate value={requestEvent.createdAt} />
            </strong>{' '}
            para plantar{' '}
            <strong>
              {process.area}
              &nbsp;ha
            </strong>{' '}
            da cultivar <strong>{process.cultivar.name}</strong> na categoria{' '}
            <strong>
              <SeedCategory value={process.seedCategory} />
            </strong>{' '}
            para a safra <strong>{process.seasonYear.id}</strong> anexando este{' '}
            <DownloadAttachmentLink id={requestEvent.attachment.id} process={process}>
              documento
            </DownloadAttachmentLink>{' '}
            para comprovar a origem das sementes.
          </Typography>
          <Typography>
            {user.email === responseEvent.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{responseEvent.user}</strong> da <strong>{responseEvent.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={responseEvent} process={process} /> em{' '}
            <strong>
              <FormattedDate value={responseEvent.createdAt} />
            </strong>
            .
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
}
