import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MySnackbarContentWrapper as SnackbarContent } from '../../components/CSnackbar';
import { Typography } from '@material-ui/core';

const validationSchema = Yup.object().shape({
  message: Yup.string()
    .required('Informe uma justificativa')
    .max(1000, 'Informe uma justificative com no máximo 1000 caracteres'),
});

export const CancelProcessContent = () => (
  <Typography>Por favor, informe uma justificativa para cancelar este processo.</Typography>
);

export const CancelProcessActions = ({ onEvent, process }) => (
  <Formik
    initialValues={{ message: '' }}
    enableReinitialize
    validationSchema={validationSchema}
    onSubmit={async (values, actions) => {
      await onEvent(async () => {
        try {
          await process.cancel({
            message: values.message,
          });
          values.message = '';
          actions.setStatus({ success: 'Processo cancelado!' });
        } catch (error) {
          console.log(error);
          actions.setStatus({
            error: 'Não foi possível cancelar o processo!',
          });
        }
      });
      actions.setSubmitting(false);
    }}
  >
    {({ values, errors, status, setStatus, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={status && status.error !== undefined}
          onClose={() => setStatus({ error: undefined })}
        >
          <SnackbarContent
            onClose={() => setStatus({ error: undefined })}
            variant="error"
            message={status && status.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={status && status.success !== undefined}
          onClose={() => setStatus({ success: undefined })}
        >
          <SnackbarContent
            onClose={() => setStatus({ success: undefined })}
            variant="success"
            message={status && status.success}
          />
        </Snackbar>
        <TextField
          id="message"
          name="message"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.message}
          label="Justificativa"
          multiline
          rows="2"
          margin="normal"
          InputProps={{
            inputProps: {
              maxLength: 1000,
            },
          }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          helperText={touched.message && errors.message}
          error={!!errors.message && !!touched.message}
        />
        <Button color="primary" disabled={isSubmitting} fullWidth onClick={handleSubmit} variant="contained">
          Enviar
        </Button>
      </form>
    )}
  </Formik>
);
