import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import FormattedDate from '../../components/FormattedDate';
import { useTranslation } from 'react-i18next';
import { amber } from '@material-ui/core/colors';

export default function FieldSchedule({ process }) {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2}>
      {process.fieldsGroupedByLimitDate.map(({ date, fields }) => (
        <Grid key={date} item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="subtitle2">
              Até <FormattedDate value={date} dateOnly />
            </Typography>
          </Grid>
          <Grid item container spacing={1}>
            {fields.map((field) => (
              <Grid key={field.fieldName} item container direction="row" justifyContent="space-between">
                <Grid item>
                  <Typography variant="body2" color="textSecondary">
                    {t(`FIELD_NAME_${field.fieldName}`)}
                  </Typography>
                </Grid>
                <Grid item>
                  {field.status === 'LATE' && <CancelIcon fontSize="small" color="error" />}
                  {field.status === 'WARNING' && (
                    <ErrorOutlineOutlinedIcon style={{ color: amber[800] }} color="warning" fontSize="small" />
                  )}
                  {field.status === 'OK' && <CheckCircleOutlineIcon color="primary" fontSize="small" />}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
