import { conformToMask } from 'text-mask-core';
import mask from 'text-mask-addons/dist/emailMask';

const format = (raw) => conformToMask(raw, mask).conformedValue;

const Email = {
  mask,
  format,
};

export default Email;
