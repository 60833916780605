import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import DownloadAttachmentLink from '../../components/DownloadAttachmentLink';
import FormattedDate from '../../components/FormattedDate';
import SeedCategory from '../../components/SeedCategory';

export default function RequestPhaseRespond({ process, onEvent, t, user }) {
  if ((user.isLicenser || user.isBreeder || user.isBreederViewer) && process.canRespondToRequest()) {
    const event = process.getRequestEvent();
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom>
            Existe uma nova solicitação de licenciamento feita em{' '}
            <strong>
              <FormattedDate value={event.createdAt} />
            </strong>{' '}
            por <strong>{event.user}</strong> da <strong>{event.company}</strong> para plantar{' '}
            <strong>{process.area} ha</strong> da cultivar <strong>{process.cultivar.name}</strong> na categoria{' '}
            <strong>
              <SeedCategory value={process.seedCategory} />
            </strong>{' '}
            para a safra <strong>{process.seasonYear.id}</strong> anexando este{' '}
            <DownloadAttachmentLink id={event.attachment.id} process={process}>
              documento
            </DownloadAttachmentLink>{' '}
            para comprovar a origem das sementes.
          </Typography>
          {(user.isLicenser || user.isBreeder) && (
            <Typography>
              Por favor, pressione o botão correspondente abaixo para aceitar ou rejeitar a solicitação:
            </Typography>
          )}
        </Grid>
        {(user.isLicenser || user.isBreeder) && (
          <Grid container item spacing={3}>
            <Grid item xs={6}>
              <Button
                disabled={process.deleted}
                variant="contained"
                color="primary"
                fullWidth
                onClick={async () => await onEvent(process.approveRequest)}
              >
                Aceitar solicitação
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                disabled={process.deleted}
                variant="contained"
                color="secondary"
                fullWidth
                onClick={async () => await onEvent(process.rejectRequest)}
              >
                Rejeitar solicitação
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
  return null;
}
