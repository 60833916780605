import axios from 'axios';

export default class Cultivar {
  static async list(isTesting = false) {
    const url = isTesting ? '/cultivars/search/testing' : '/cultivars';
    const params = {
      projection: 'summary',
      sort: 'name,asc',
    };
    if (isTesting) {
      params.testing = true;
    }
    try {
      const response = await axios.get(url, { params });
      return response.data._embedded.cultivars;
    } catch (error) {
      console.log(error, error.response);
    }
  }

  static async get(id) {
    try {
      const response = await axios.get(`/cultivars/${id}`, {
        params: {
          projection: 'detail',
        },
      });
      return response.data;
    } catch (error) {
      console.log(error, error.response);
    }
  }

  async filterValues() {
    try {
      const response = await axios.get('/cultivars/search/findDistinctByLicensingProcessUserHasAccessTo', {
        params: {
          projection: 'summary',
          sort: 'name,asc',
        },
      });
      return response.data._embedded.cultivars;
    } catch (error) {
      console.log(error, error.response);
    }
  }
}
