import React from 'react';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../PrivateRoute';
import LicensingProcessList from './LicensingProcessList';
import LicensingProcessDetail from './LicensingProcessDetail';

export default function Licenciamento({ nav }) {
  return (
    <Switch>
      <PrivateRoute path="/licenciamento" exact render={(props) => <LicensingProcessList {...props} nav={nav} />} />
      <PrivateRoute
        path="/licenciamento/processos/:id"
        render={(props) => <LicensingProcessDetail {...props} nav={nav} />}
      />
    </Switch>
  );
}
