import axios from 'axios';

export default class CompanyProfile {
  static async getFromCurrentUser() {
    try {
      const response = await axios.get(`/companyProfile`);
      return new CompanyProfile(response.data);
    } catch (error) {
      throw error;
    }
  }

  static async get(id) {
    try {
      const response = await axios.get(`/companyProfile/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async save(id, data) {
    try {
      await axios.put(`/companyProfile/${id}`, data);
    } catch (error) {
      throw error;
    }
  }

  constructor(data) {
    Object.assign(this, data);
  }

  isComplete() {
    return this.technician !== null && this.financeContact !== null;
  }
}
