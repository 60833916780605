import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

export default function ContractPhaseRespond({ process, onEvent, user }) {
  if (user.isProducer && process.canRespondToContract()) {
    const event = process.getIssueContractEvent();
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom>
            <strong>{event.user}</strong> da <strong>{event.company}</strong>{' '}
            <EventMessage active event={event} process={process} /> em{' '}
            <strong>
              <FormattedDate value={event.createdAt} />
            </strong>
            .
          </Typography>
          <Typography>
            Por favor, pressione o botão correspondente abaixo para assinar ou rejeitar o contrato:
          </Typography>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={6}>
            <Button
              disabled={process.deleted}
              variant="contained"
              color="primary"
              fullWidth
              onClick={async () => await onEvent(process.signContract)}
            >
              Assinar contrato
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={process.deleted}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={async () => await onEvent(process.rejectContract)}
            >
              Rejeitar contrato
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
}
