import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export default function FieldsPhaseIssue({ process, onEvent, user }) {
  if ((user.isLicenser || user.isBreeder) && process.canIssueFieldRegistrationAuthorization()) {
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Button
            disabled={process.deleted}
            variant="contained"
            color="primary"
            fullWidth
            onClick={async () => await onEvent(process.issueFieldRegistrationAuthorization)}
          >
            Emitir autorização para inscrição de campo
          </Button>
        </Grid>
      </Grid>
    );
  }
  return null;
}
