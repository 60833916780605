import 'react-app-polyfill/ie9';
import 'core-js/es/array/includes';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import qs from 'qs';
import mixpanel from 'mixpanel-browser';

import { MixpanelContext } from './MixpanelContext';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import 'typeface-lato';
import './i18n';

import App from './App';
import Auth from './auth';
import { unregister } from './registerServiceWorker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

if (['www.netseeds.com.br', 'netseeds.com.br'].includes(window.location.hostname.toLowerCase())) {
  mixpanel.init('77ec9ddc69dad7f37435da7f1d9fb9bc');
} else {
  mixpanel.init('a84576e66521de569494f24b66486dfa');
}

mixpanel.track('appLoaded');

dayjs.locale('pt-br');

axios.defaults.baseURL = window.___env___.API_BASE_URL;
axios.defaults.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });
axios.interceptors.request.use((config) => {
  if (Auth.token) {
    config.headers['Authorization'] = `Bearer ${Auth.token}`;
    config.headers['X-AUTH-TOKEN'] = Auth.token;
  } else {
    delete config.headers['Authorization'];
    delete config.headers['X-AUTH-TOKEN'];
  }
  return config;
});
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      Auth.logout();
    }
    return error;
  }
);

const theme = createTheme({
  palette: {
    primary: { main: '#008900' },
    secondary: { main: '#D50000' },
  },
  typography: {
    fontFamily: 'Lato',
    fontWeightMedium: 700,
    body1: {
      fontSize: 14,
    },
  },
});

const ThemedApp = () => (
  <MuiThemeProvider theme={theme}>
    <MixpanelContext.Provider value={mixpanel}>
      <App />
    </MixpanelContext.Provider>
  </MuiThemeProvider>
);

ReactDOM.render(<ThemedApp />, document.getElementById('root'));
// registerServiceWorker();
unregister();
