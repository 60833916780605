import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    pt_br: {
      translation: {
        REQUEST_TO_PRODUCE_SENT: 'Solicitação para produzir enviada',
        REQUEST_TO_PRODUCE_SENT_ACTIVE: 'enviou solicitação para produzir',
        REQUEST_TO_PRODUCE_APPROVED: 'Solicitação para produzir aceita',
        REQUEST_TO_PRODUCE_APPROVED_ACTIVE: 'aceitou a solicitação para produzir',
        REQUEST_TO_PRODUCE_REJECTED: 'Solicitação para produzir rejeitada',
        REQUEST_TO_PRODUCE_REJECTED_ACTIVE: 'rejeitou a solicitação para produzir',
        CONTRACT_ISSUED: 'Contrato emitido',
        CONTRACT_ISSUED_ACTIVE: 'emitiu o contrato',
        CONTRACT_ISSUED_ACTIVE_WITH_LINK: `emitiu o <a href="${window.___env___.API_BASE_URL}/licensingProcessAttachments/{{id}}/download">contrato</a>`,
        CONTRACT_SIGNED: 'Contrato assinado',
        CONTRACT_SIGNED_ACTIVE: 'assinou o contrato',
        CONTRACT_REJECTED: 'Contrato rejeitado',
        CONTRACT_REJECTED_ACTIVE: 'rejeitou o contrato',
        FIELD_REGISTRATION_AUTHORIZATION_ISSUED: 'Autorização para inscrição de campo emitida',
        FIELD_REGISTRATION_AUTHORIZATION_ISSUED_ACTIVE: 'emitiu a autorização para inscrição de campo',
        FIELD_REGISTRATION_CONFIRMATION_SENT: 'Número de inscrição de campo enviada',
        FIELD_REGISTRATION_CONFIRMATION_SENT_ACTIVE:
          'enviou o número {{fieldRegistrationNumber}} da homologação de inscrição de campo',
        YIELD_INFORMATION_UPDATED: 'Informação de produção atualizada',
        YIELD_INFORMATION_UPDATED_ACTIVE: 'atualizou a informação de produção',
        PROCESS_IMPORTED: 'Informação do processo importada',
        PROCESS_IMPORTED_ACTIVE: 'importou a informação do processo',
        MESSAGE_POSTED: 'Mensagem enviada',
        MESSAGE_POSTED_ACTIVE: 'enviou uma mensagem',
        PROCESS_CONSOLIDATED: 'Processo consolidado',
        PROCESS_CONSOLIDATED_ACTIVE: 'consolidou o processo',
        REMINDER_TRIGGERED: 'Lembrete disparado',
        REMINDER_TRIGGERED_ACTIVE: 'disparou um lembrete',
        SEED_CATEGORY_BASIC: 'Básica',
        SEED_CATEGORY_S1: 'S1',
        SEED_CATEGORY_S2: 'S2',
        SEED_CATEGORY_C1: 'C1',
        SEED_CATEGORY_C2: 'C2',
        SEED_CATEGORY_NULL: 'n/d',
        FIELD_NAME_APPROVED_AREA: 'Área aprovada',
        FIELD_NAME_APPROVED_SEED_AMOUNT: 'Semente aprovada',
        FIELD_NAME_COMMERCIALIZED_SEED_AMOUNT: 'Semente comercializada',
        FIELD_NAME_COMMERCIALIZED_SEED_VALUE: 'Valor total comercializado',
        FIELD_NAME_HOMOLOGATED_AREA: 'Área homologada',
        FIELD_NAME_OWN_USE_SEED_AMOUNT: 'Semente para uso próprio',
        FIELD_NAME_PROCESSED_SEED_AMOUNT: 'Semente beneficiada',
        FIELD_NAME_RECEIVED_SEED_AMOUNT: 'Semente bruta',
      },
    },
  },
  lng: 'pt_br',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
