import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { withStyles } from '@material-ui/core/styles';
import { HomeLayout } from '../layouts';
import heroBackground from '../images/hero.jpg';
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const styles = (theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  hero: {
    background: `url(${heroBackground})`,
    backgroundSize: 'cover',
    color: theme.palette.common.white,
    paddingTop: theme.spacing(20),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(50),
    paddingLeft: theme.spacing(1),
  },
  content: {
    padding: `${theme.spacing(8)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  button: {
    paddingTop: theme.spacing(2),
  },
});

const HomePage = ({ classes, nav }) => (
  <HomeLayout nav={nav}>
    <Grid container direction="column">
      <Grid item className={classes.hero}>
        <Typography component="h2" variant="h4" align="center" color="inherit" gutterBottom>
          A maneira mais fácil de licenciar cultivares
        </Typography>
        <Typography align="center" component="p">
          <Button color="primary" component={Link} to="/cultivares" variant="contained">
            Comece por aqui <ArrowForward />
          </Button>
        </Typography>
      </Grid>
      <Grid container item direction="column" alignItems="center">
        <Grid item className={classes.content} xs={12} sm={6}>
          <Typography component="h2" variant="h4" gutterBottom>
            Obtentores + Produtores
          </Typography>
          <Typography component="p" variant="body1">
            O NetSeeds aproxima obtentores e produtores de sementes, trazendo rapidez e agilidade ao processo de
            licenciamento de cultivares protegidas. Além de ser uma plataforma para o licenciamento, o NetSeeds também
            serve como um canal de comunicação para divulgação de cultivares.
          </Typography>
        </Grid>
        <Grid item className={classes.content} xs={12} sm={6}>
          <Typography component="h2" variant="h4" gutterBottom>
            Cultivares de Soja, Trigo e Aveia
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            Através do NetSeeds, obtentores de todo o Brasil podem divulgar as suas cultivares e oferecer uma maneira
            eficiente para que produtores de sementes possam conhecer e licenciar a produção de sementes protegidas.
          </Typography>
          <Typography align="center" className={classes.button} component="p">
            <Button component={Link} to="/cultivares" variant="contained">
              Conheça as cultivares disponíveis
            </Button>
          </Typography>
        </Grid>
        <Grid container item className={classes.content} xs={12} sm={8}>
          <Grid item xs={12} sm={6}>
            <Typography component="h2" variant="h4" gutterBottom>
              NetSeeds para Obtentores
            </Typography>
            <List disablePadding>
              <ListItem>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText>Um canal para divulgar cultivares</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText>Um processo rápido e ágil de licenciamento</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText>Uma ferramenta para acompanhar o rendimento das cultivares</ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component="h2" variant="h4" gutterBottom>
              NetSeeds para Produtores
            </Typography>
            <List disablePadding>
              <ListItem>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText>Um canal para conhecer cultivares</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText>Um processo rápido e ágil de licenciamento</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ChevronRightIcon />
                </ListItemIcon>
                <ListItemText>Uma ferramenta para acompanhar o rendimento das cultivares</ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Grid item className={classes.content} xs={12} sm={6}>
          <Typography component="h2" variant="h4" gutterBottom>
            Como funciona?
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            Através do NetSeeds, o Produtor de Semente pode pesquisar e comparar cultivares protegidas de sementes de
            Soja, Trigo e Aveia e fazer a solicitação para produzir sementes ao Obtentor. O processo segue até o fim da
            safra, quando o Produtor informa ao Obtentor o resultado da sua produção. Dessa forma, o processo transcorre
            com muito mais rapidez e agilidade e ambas as partes tem o benefício de visualizar todo o processo através
            de uma ferramenta poderosa.
          </Typography>
          <Typography align="center" className={classes.button} component="p">
            <Button href="mailto:netseeds@netseeds.com.br?Subject=Saiba mais" variant="contained">
              Saiba mais
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </HomeLayout>
);

export default withStyles(styles)(HomePage);
