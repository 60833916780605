import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export default function SectionHeader({ title, subTitle }) {
  return (
    <Grid item xs style={{ marginTop: 12 }}>
      <Typography component="h2" variant="subtitle1">
        {title}
      </Typography>
      <Typography component="p">{subTitle}</Typography>
    </Grid>
  );
}
