import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Menu } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';

export default function MobileMenu({ anchorEl, classes, onClose, onProfileClick, open, user }) {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
    >
      <MenuItem component={Link} to="/cultivares">
        Cultivares
      </MenuItem>
      <MenuItem component={Link} to="/licenciamento">
        Licenciamento
      </MenuItem>
      {user && (user.isLicenser || user.isBreeder || user.isBreederViewer) && (
        <MenuItem component={Link} to="/produtores">
          Produtores
        </MenuItem>
      )}
      {user && (
        <MenuItem onClick={onProfileClick}>
          <AccountCircle className={classes.leftIcon} />
          {user.name}
        </MenuItem>
      )}
      {!user && (
        <MenuItem component={Link} to="/entrar">
          Entrar
        </MenuItem>
      )}
    </Menu>
  );
}
