import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { withStyles } from '@material-ui/core/styles';
import FormattedNumber from '../../components/FormattedNumber';

const styles = (theme) => ({
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 1)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
});

export default withStyles(styles)(({ classes, onEvent, process }) => (
  <Grid container direction="column" spacing={2}>
    {process.royaltyRateSchemeType === 'reference_value' && (
      <>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Valor de referência
              </Typography>
            </Grid>
            <Grid item style={{ padding: '6px 0px 0px 4px' }}>
              <Tooltip
                interactive
                leaveDelay={300}
                placement="right"
                classes={{ tooltip: classes.lightTooltip }}
                title={
                  <Typography variant="caption">
                    Valor calculado conforme cláusula 7 do contrato de licenciamento com os dados obtidos do{' '}
                    <Link href="https://cepea.esalq.usp.br/" target="_blank">
                      CEPEA/ESALQ
                    </Link>
                  </Typography>
                }
              >
                <HelpIcon style={{ fontSize: 16 }} color="action" />
              </Tooltip>
            </Grid>
          </Grid>
          <Typography variant="subtitle2">
            <FormattedNumber
              prefix="R$"
              value={
                process.royaltyCalculationDetails.referenceValue !== null
                  ? process.royaltyCalculationDetails.referenceValue * 1000
                  : null
              }
              suffix=" / t"
              maximumFractionDigits={4}
            />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Valor de <em>royalties</em>
          </Typography>
          <Typography variant="subtitle2">
            <FormattedNumber value={process.royaltyCalculationDetails.royaltyValue} prefix="R$" />
          </Typography>
        </Grid>
      </>
    )}
    {(process.royaltyRateSchemeType === 'fixed' || process.royaltyRateSchemeType === 'ranges') && (
      <>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Percentual de <em>royalties</em>
          </Typography>
          <Typography variant="subtitle2">
            <FormattedNumber value={process.royaltyCalculationDetails.royaltyRate * 100} suffix="%" />
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Valor de <em>royalties</em>
          </Typography>
          <Typography variant="subtitle2">
            <FormattedNumber value={process.royaltyCalculationDetails.royaltyValue} prefix="R$" />
          </Typography>
        </Grid>
      </>
    )}
  </Grid>
));
