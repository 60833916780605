import React from 'react';
import { Card, CardContent, CardHeader, Grid, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  header: {
    paddingBottom: 0,
  },
  item: {
    marginBottom: theme.spacing(2),
  },
});

export default withStyles(styles)(({ children, classes, title }) => (
  <Grid item classes={{ item: classes.item }}>
    <Card>
      <CardHeader title={title} className={classes.header} />
      <CardContent>{children}</CardContent>
    </Card>
  </Grid>
));
