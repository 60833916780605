import React from 'react';
import { Typography } from '@material-ui/core';

export default function FieldsPhaseIssueAwait({ process, user }) {
  if (user.isProducer && process.canIssueFieldRegistrationAuthorization()) {
    return (
      <Typography>
        A autorização para inscrição de campos será emitido pelo licenciador <strong>{process.licenser.name}</strong> em
        breve, por favor aguarde.
      </Typography>
    );
  }
  return null;
}
