import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem, Menu } from '@material-ui/core';

export default function DesktopMenu({ anchorEl, onClose, open, user }) {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
    >
      {user.isProducer && (
        <MenuItem component={Link} to="/produtores">
          Editar o cadastro
        </MenuItem>
      )}
      <MenuItem component={Link} to="/alterar-senha">
        Alterar a senha
      </MenuItem>
      <MenuItem component={Link} to="/sair">
        Sair
      </MenuItem>
    </Menu>
  );
}
