import axios from 'axios';

export default class SeasonYear {
  async list() {
    try {
      const response = await axios.get('/seasonYears', {
        projection: 'summary',
        sort: 'name,asc',
      });
      return response.data._embedded.seasonYears;
    } catch (error) {
      console.log(error, error.response);
    }
  }

  async filterValues() {
    try {
      const response = await axios.get('/seasonYears/search/findDistinctByLicensingProcessUserHasAccessTo', {
        params: {
          projection: 'summary',
          sort: 'name,asc',
        },
      });
      return response.data._embedded.seasonYears;
    } catch (error) {
      console.log(error, error.response);
    }
  }
}
