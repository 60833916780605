import React from 'react';
import {
  Grid,
  withStyles,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { MainLayout } from '../../../layouts';

function Item({ classes = {}, producer }) {
  return (
    <Grid item xs={12}>
      <CardActionArea component={Link} to={`/produtores/${producer.textId}`}>
        <Divider />
        <CardContent>
          <Grid container item spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">{producer.name}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Grid>
  );
}

function SeasonYearCard({ seasonYear, producers = [] }) {
  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader title={seasonYear} />
        <Grid container>
          {producers.map((producer) => (
            <Item key={producer.textId} producer={producer} />
          ))}
        </Grid>
      </Card>
    </Grid>
  );
}

const Producers = ({ classes, user, nav, producers }) => {
  return (
    <MainLayout
      nav={nav}
      title="Produtores"
      ready={producers.length !== 0}
      breadcrumbs={[
        { path: '/', label: 'Início' },
        { path: '/produtores', label: 'Produtores' },
      ]}
    >
      <Grid container item direction="row" spacing={3} alignItems="flex-start">
        {Object.keys(producers)
          .sort()
          .reverse()
          .map((seasonYear) => (
            <SeasonYearCard key={seasonYear} seasonYear={seasonYear} producers={producers[seasonYear]} />
          ))}
      </Grid>
    </MainLayout>
  );
};

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

export default withStyles(styles)(Producers);
