import axios from 'axios';

export default class LicensingProcessEvent {
  constructor(data) {
    Object.assign(this, data);
  }

  static async create(data) {
    try {
      await axios.post(`/licensingProcessEvents`, data);
    } catch (error) {
      throw error;
    }
  }
}
