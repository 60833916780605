import React, { useState, useEffect, useContext } from 'react';
import { Switch } from 'react-router-dom';
import ProducerProfile from '../../../api/ProducerProfile';
import { LoadingContext } from '../../../LoadingContext';
import PrivateRoute from '../../../PrivateRoute';
import List from './List';
import Detail from './Detail';

export default function Licenciador({ user, nav }) {
  const [, setError] = useState(null);

  const [producers, setProducers] = useState([]);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  useEffect(() => {
    async function listProducers() {
      startLoading();
      const producers = await ProducerProfile.mapBySeasonYear();
      stopLoading();
      setProducers(producers.data);
    }
    try {
      listProducers();
    } catch (e) {
      setError(e);
    }
  }, [startLoading, stopLoading]);

  return (
    <Switch>
      <PrivateRoute
        path="/produtores"
        exact
        render={(props) => <List nav={nav} user={user} producers={producers} {...props} />}
      />
      <PrivateRoute
        path="/produtores/:id"
        render={({ match }) => <Detail nav={nav} user={user} producerId={match.params.id} />}
      />
    </Switch>
  );
}
