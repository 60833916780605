import React from 'react';
import { Card, CardContent, CardHeader, Grid, Link, Typography, Divider } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNewRounded';
import CPF from '../../../shared/CPF';
import CNPJ from '../../../shared/CNPJ';
import Renasem from '../../../shared/Renasem';
import PostalCode from '../../../shared/PostalCode';
import Telephone from '../../../shared/Telephone';

export default function ProducerCard({ producer }) {
  return (
    <Card>
      <CardHeader title="Cadastro" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Nome
            </Typography>
            <Typography variant="subtitle2" title="Nome do Produtor">
              {producer.name}
            </Typography>
          </Grid>
          {producer.countryTaxNumberType === 'cnpj' && (
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Razão social
              </Typography>
              <Typography variant="subtitle2" title="Razão Social do Produtor">
                {producer.legalName || '-'}
              </Typography>
            </Grid>
          )}
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              {producer.countryTaxNumberType === 'cnpj' ? 'CNPJ' : 'CPF'}
            </Typography>
            <Typography
              variant="subtitle2"
              title={`${producer.countryTaxNumberType === 'cnpj' ? 'CNPJ' : 'CPF'} do Produtor`}
            >
              {producer.countryTaxNumberType === 'cnpj' && producer.cnpj && CNPJ.format(producer.cnpj)}
              {producer.countryTaxNumberType === 'cpf' && producer.cpf && CPF.format(producer.cpf)}
              {!producer.cpf && !producer.cnpj && '-'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Inscrição Estadual
            </Typography>
            <Typography variant="subtitle2" title="Inscrição Estadual do Produtor">
              {producer.stateTaxNumber || '-'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              RENASEM
            </Typography>
            {producer.renasem && (
              <Link
                href={`http://sistemasweb.agricultura.gov.br/renasem/psq_consultarenasems.do?valor(cdRenasemPesquisa)=${Renasem.format(
                  producer.renasem
                )}`}
                target="_blank"
                variant="subtitle2"
                title="RENASEM do Produtor"
              >
                {Renasem.format(producer.renasem)}
                &nbsp;
                <OpenInNewIcon fontSize="inherit" />
              </Link>
            )}
            {!producer.renasem && (
              <Typography variant="subtitle2" title="RENASEM do Produtor">
                -
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Endereço
            </Typography>
            <Typography variant="subtitle2" title="Logradouro do Endereço do Produtor">
              {producer.address.streetAddress || '-'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              CEP
            </Typography>
            <Typography variant="subtitle2" title="CEP do Endereço do Produtor">
              {PostalCode.format(producer.address.postalCode) || '-'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Município
            </Typography>
            <Typography variant="subtitle2" title="Munícipio do Endereço do Produtor">
              {producer.address.city || '-'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Estado
            </Typography>
            <Typography variant="subtitle2" title="Estado do Endereço do Produtor">
              {producer.address.state || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary">
              Telefone
            </Typography>
            <Typography variant="subtitle2" title="Telefone do Produtor">
              {(producer.phoneNumber && Telephone.format(producer.phoneNumber)) || '-'}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      {producer.countryTaxNumberType === 'cnpj' && (
        <>
          <Divider />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="overline">Representante Legal</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Nome
                </Typography>
                <Typography variant="subtitle2" title="Nome do Representante Legal">
                  {(producer.legalRepresentative && producer.legalRepresentative.fullName) || '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  CPF
                </Typography>
                <Typography variant="subtitle2" title="CPF do Representante Legal">
                  {(producer.legalRepresentative && CPF.format(producer.legalRepresentative.countryTaxNumber ?? '')) ||
                    '-'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}

      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">Responsável Técnico</Typography>
          </Grid>
          {producer.technician === null && (
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Não cadastrado
              </Typography>
            </Grid>
          )}
          {producer.technician !== null && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Nome
                </Typography>
                <Typography variant="subtitle2" title="Nome do Responsável Técnico">
                  {producer.technician?.fullName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Telefone
                </Typography>
                <Typography variant="subtitle2" title="Telefone do Responsável Técnico">
                  {(producer.technician?.phoneNumber && Telephone.format(producer.technician?.phoneNumber)) || '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  E-mail
                </Typography>
                <Link
                  href={`mailto:${producer.technician?.email}`}
                  variant="subtitle2"
                  title="E-mail do Responsável Técnico"
                >
                  {producer.technician?.email}
                </Link>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  CPF
                </Typography>
                <Typography variant="subtitle2" title="CPF do Responsável Técnico">
                  {(producer.technician?.countryTaxNumber && CPF.format(producer.technician?.countryTaxNumber)) || '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  RENASEM
                </Typography>
                {producer.technician?.renasem && (
                  <Link
                    href={`http://sistemasweb.agricultura.gov.br/renasem/psq_consultarenasems.do?valor(cdRenasemPesquisa)=${Renasem.format(
                      producer.technician?.renasem
                    )}`}
                    target="_blank"
                    variant="subtitle2"
                    title="RENASEM do Responsável Técnico"
                  >
                    {Renasem.format(producer.technician?.renasem)}
                    &nbsp;
                    <OpenInNewIcon fontSize="inherit" />
                  </Link>
                )}
                {!producer.technician?.renasem && (
                  <Typography variant="subtitle2" title="RENASEM do Responsável Técnico">
                    -
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="overline">Contato Financeiro</Typography>
          </Grid>
          {producer.financeContact === null && (
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Não cadastrado
              </Typography>
            </Grid>
          )}
          {producer.financeContact !== null && (
            <>
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  Nome
                </Typography>
                <Typography variant="subtitle2" title="Nome do Contato Financeiro">
                  {producer.financeContact?.fullName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Telefone
                </Typography>
                <Typography variant="subtitle2" title="Telefone do Contato Financeiro">
                  {(producer.financeContact?.phoneNumber && Telephone.format(producer.financeContact?.phoneNumber)) ||
                    '-'}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  E-mail
                </Typography>
                <Link
                  href={`mailto:${producer.financeContact?.email}`}
                  variant="subtitle2"
                  title="E-mail do Contato Financeiro"
                >
                  {producer.financeContact?.email}
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
