import axios from 'axios';
import jwtDecode from 'jwt-decode';

export default class Auth {
  static _userFromToken = (token) => {
    if (token) {
      const claims = jwtDecode(token);
      return {
        name: claims.displayName,
        email: claims.sub,
        roles: claims.roles,
        isProducer: claims.roles.includes('PRODUCER'),
        isLicenser: claims.roles.includes('LICENSER'),
        isBreeder: claims.roles.includes('BREEDER'),
        isBreederViewer: claims.roles.includes('BREEDER_VIEWER'),
      };
    }
  };

  static token = window.localStorage.getItem('token');

  static user = Auth._userFromToken(Auth.token);

  static subscribers = [];

  static isAuthenticated = () => !!Auth.token;

  static subscribe(cb) {
    Auth.subscribers.push(cb);
  }

  static unsubscribe(cb) {
    const index = Auth.subscribers.indexOf(cb);
    if (index !== -1) {
      Auth.subscribers.splice(index, 1);
    }
  }

  static logout = () => {
    Auth.token = undefined;
    Auth.user = undefined;
    window.localStorage.removeItem('token');
    Auth.subscribers.forEach((subscriber) => subscriber(Auth.user));
  };

  static recoverPassword = async (email) => {
    try {
      await axios.post('/userProfile/recoverPassword', {
        email,
      });
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        message: error.response.data.errors,
      };
    }
  };

  static resetPassword = async (password, passwordConfirmation, token) => {
    try {
      await axios.post('/userProfile/resetPassword', {
        password,
        passwordConfirmation,
        token,
      });
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        message: error.response.data.errors,
      };
    }
  };

  static changePassword = async (password, newPassword, newPasswordConfirmation) => {
    try {
      await axios.post('/userProfile/changePassword', {
        password,
        newPassword,
        newPasswordConfirmation,
      });
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        message: error.response.data.errors,
      };
    }
  };

  static login = async (email, password) => {
    try {
      const response = await axios.post(
        '/login',
        {
          email,
          password,
        },
        {
          auth: {
            username: email,
            password,
          },
        }
      );
      Auth.token = response.data.token;
      if (Boolean(window.___env___.STORE_TOKEN_IN_LOCAL_STORAGE) === true) {
        window.localStorage.setItem('token', Auth.token);
      }
      Auth.user = Auth._userFromToken(Auth.token);
      Auth.subscribers.forEach((subscriber) => subscriber(Auth.user));
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        message: error.response.data.message,
      };
    }
  };
}
