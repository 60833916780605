import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Snackbar, TextField, withStyles } from '@material-ui/core';
import Auth from '../auth';
import { DialogLayout } from '../layouts';
import { MySnackbarContentWrapper } from '../components/CSnackbar';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Informe a sua senha atual!'),
  newPassword: Yup.string()
    .min(8, 'Informe uma nova senha com no mínimo 8 caracteres!')
    .required('Informe uma nova senha com no mínimo 8 caracteres!'),
  newPasswordConfirmation: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Informe novamente a sua nova senha!')
    .required('Informe novamente a sua nova senha!'),
});

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class AlterarSenha extends React.Component {
  state = { error: undefined, errorMessage: undefined, success: undefined };
  handleCloseSuccessSnackbar = () => {
    this.props.history.push('/entrar');
  };
  handleCloseErrorSnackbar = () => {
    this.setState({ error: undefined });
  };
  render() {
    const { classes } = this.props;
    const { success, error, errorMessage } = this.state;
    return (
      <DialogLayout title="Alterar a senha">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={success === true}
          autoHideDuration={3000}
          onClose={this.handleCloseSuccessSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSuccessSnackbar}
            variant="success"
            message="A senha foi alterada com sucesso! Aguarde..."
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error === true}
          onClose={this.handleCloseErrorSnackbar}
        >
          <MySnackbarContentWrapper onClose={this.handleCloseErrorSnackbar} variant="error" message={errorMessage} />
        </Snackbar>
        <Formik
          initialValues={{
            password: '',
            newPassword: '',
            newPasswordConfirmation: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            this.setState({ error: false });
            const result = await Auth.changePassword(
              values.password,
              values.newPassword,
              values.newPasswordConfirmation
            );
            if (result.success === true) {
              actions.setSubmitting(false);
              this.setState({ success: true });
            } else {
              actions.setSubmitting(false);
              this.setState({ error: true, errorMessage: result.message });
            }
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                id="password"
                name="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label="Senha atual"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.password && errors.password}
                error={!!errors.password && !!touched.password}
              />

              <TextField
                id="newPassword"
                name="newPassword"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPassword}
                label="Nova senha"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.newPassword && errors.newPassword}
                error={!!errors.newPassword && !!touched.newPassword}
              />
              <TextField
                id="newPasswordConfirmation"
                name="newPasswordConfirmation"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.newPasswordConfirmation}
                label="Confirmação da nova senha"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
                error={!!errors.newPasswordConfirmation && !!touched.newPasswordConfirmation}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting || success === true}
              >
                Enviar
              </Button>
            </form>
          )}
        </Formik>
      </DialogLayout>
    );
  }
}

export default withStyles(styles)(AlterarSenha);
