import React from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Typography,
  withStyles,
  CardActionArea,
  Paper,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTimeRounded';
import ArchiveIcon from '@material-ui/icons/ArchiveRounded';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import PrevPageIcon from '@material-ui/icons/ChevronLeft';
import NextPageIcon from '@material-ui/icons/ChevronRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import LicensingProcess from '../../api/LicensingProcess';
import LicensingProcessReport from '../../api/LicensingProcessReport';
import CultivarAPI from '../../api/Cultivar';
import SeasonYearAPI from '../../api/SeasonYear';
import { LoadingContext } from '../../LoadingContext';
import { MainLayout } from '../../layouts';
import { withTranslation } from 'react-i18next';
import FormattedNumber from '../../components/FormattedNumber';
import FormattedDate from '../../components/FormattedDate';

const CheckboxGroup = ({ entries, label, selected, ...other }) => (
  <Grid item>
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {entries.map((entry) => (
          <FormControlLabel
            key={entry.value}
            control={
              <Checkbox {...other} checked={selected !== undefined && selected.has(entry.value)} value={entry.value} />
            }
            label={entry.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  </Grid>
);

class LicensingProcessList extends React.Component {
  static contextType = LoadingContext;

  static getCurrentPageFromLocalStorage = () => {
    try {
      return window.localStorage.getItem('licensingProcess.list.currentPage') || 0;
    } catch {
      return 0;
    }
  };

  static getFiltersFromLocalStorage = () => {
    try {
      const filters = JSON.parse(window.localStorage.getItem('licensingProcess.list.filters')) || {};
      return Object.keys(filters).reduce((acc, key) => {
        acc[key] = new Set(filters[key]);
        return acc;
      }, {});
    } catch {
      return {};
    }
  };

  static saveCurrentPageToLocalStorage = (currentPage) => {
    window.localStorage.setItem('licensingProcess.list.currentPage', currentPage);
  };
  static saveFiltersToLocalStorage = (filters) => {
    const filtersJSON = JSON.stringify(
      Object.keys(filters).reduce((acc, key) => {
        acc[key] = [...filters[key]];
        return acc;
      }, {})
    );
    window.localStorage.setItem('licensingProcess.list.filters', filtersJSON);
  };

  state = {
    cultivars: [],
    licensingProcesses: { items: [], page: {} },
    ready: false,
    seasonYears: [],
    currentPage: LicensingProcessList.getCurrentPageFromLocalStorage(),
    filters: LicensingProcessList.getFiltersFromLocalStorage(),
  };

  async componentDidMount() {
    const { startLoading, stopLoading } = this.context;
    startLoading();
    const cultivars = await new CultivarAPI().filterValues();
    const seasonYears = await new SeasonYearAPI().filterValues();
    stopLoading();
    await this.fetch();
    const ready = true;
    this.setState({ cultivars, ready, seasonYears });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page }, this.fetch);
    LicensingProcessList.saveCurrentPageToLocalStorage(page);
  };

  handleFilterChange = (event, checked) => {
    const { filters } = this.state;
    const selectedSet = filters[event.target.name] || new Set();
    if (checked) {
      selectedSet.add(event.target.value);
    } else {
      selectedSet.delete(event.target.value);
    }
    filters[event.target.name] = selectedSet;
    const currentPage = 0;
    this.setState({ filters, currentPage }, this.fetch);
    LicensingProcessList.saveFiltersToLocalStorage(filters);
    LicensingProcessList.saveCurrentPageToLocalStorage(currentPage);
  };

  fetch = async () => {
    const { currentPage: page, filters } = this.state;
    const { startLoading, stopLoading } = this.context;
    startLoading();
    const licensingProcesses = await LicensingProcess.query({
      page,
      ...Object.keys(filters).reduce((acc, key) => {
        acc[key] = [...filters[key]];
        return acc;
      }, {}),
    });
    stopLoading();
    this.setState({ licensingProcesses });
  };

  export = async () => {
    const { filters } = this.state;
    const { startLoading, stopLoading } = this.context;
    startLoading();
    const reportDownloadLink = await LicensingProcessReport.create({
      ...Object.keys(filters).reduce((acc, key) => {
        acc[key] = [...filters[key]];
        return acc;
      }, {}),
    });
    var link = document.createElement('a');
    link.href = reportDownloadLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    stopLoading();
  };

  firstPage = () => {
    this.handlePageChange(0);
  };

  prevPage = () => {
    this.handlePageChange(this.state.licensingProcesses.page.number - 1);
  };

  nextPage = () => {
    this.handlePageChange(this.state.licensingProcesses.page.number + 1);
  };

  lastPage = () => {
    this.handlePageChange(this.state.licensingProcesses.page.totalPages - 1);
  };

  render() {
    const { classes, nav, t } = this.props;
    const { cultivars, filters, licensingProcesses, ready, seasonYears } = this.state;
    const { user } = this.context;
    return (
      <MainLayout
        actions={
          <React.Fragment>
            {(user.isLicenser || user.isBreeder) && (
              <Button className={classes.button} title="Salvar em Excel" onClick={this.export} variant="text">
                <ArchiveIcon />
              </Button>
            )}
          </React.Fragment>
        }
        nav={nav}
        title="Licenciamento"
        ready={ready}
        breadcrumbs={[
          { path: '/', label: 'Início' },
          { path: '/licenciamento', label: 'Licenciamento' },
        ]}
      >
        <Grid container wrap="nowrap" justifyContent="space-between">
          <Grid container item xs={12} sm={2} direction="column" alignItems="flex-start" spacing={2}>
            <CheckboxGroup
              entries={seasonYears.map((seasonYear) => ({
                label: seasonYear.id,
                value: seasonYear.id,
              }))}
              label="Safra"
              name="seasonYear"
              onChange={this.handleFilterChange}
              selected={filters.seasonYear}
            />
            <CheckboxGroup
              entries={cultivars.map((cultivar) => ({
                label: cultivar.name,
                value: cultivar.id,
              }))}
              label="Cultivar"
              name="cultivar"
              onChange={this.handleFilterChange}
              selected={filters.cultivar}
            />
          </Grid>
          <Grid container item xs={12} sm={10} spacing={2} justifyContent="flex-start">
            {licensingProcesses &&
              licensingProcesses.items.map((licensingProcess) => (
                <Grid item key={licensingProcess.id} xs={12} sm={4}>
                  <Card>
                    <CardActionArea component={Link} to={`/licenciamento/processos/${licensingProcess.id}`}>
                      <CardHeader
                        title={`#${licensingProcess.id}`}
                        titleTypographyProps={{
                          align: 'right',
                          variant: 'subtitle2',
                        }}
                        classes={{
                          root: classes.cardHeader,
                          content: classes.cardHeaderContent,
                        }}
                      />
                      <CardContent>
                        <Typography component="h5" variant="subtitle2" gutterBottom>
                          {(user.isLicenser || user.isBreeder || user.isBreederViewer) &&
                            licensingProcess.producer.name}
                          {user.isProducer && licensingProcess.breeder.name}
                        </Typography>
                        <Typography component="p" variant="body2" gutterBottom>
                          {licensingProcess.seasonYear.id}
                          <br />
                          {licensingProcess.cultivar.crop.name}
                          <br />
                          {licensingProcess.cultivar.name}
                          <br />
                          {t(`SEED_CATEGORY_${licensingProcess.seedCategory}`)}
                          <br />
                          <FormattedNumber value={licensingProcess.area} suffix="ha" />
                        </Typography>
                        <Grid container spacing={1} alignItems="center" wrap="nowrap">
                          <Grid item>
                            <AccessTimeIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2">{t(licensingProcess.latestEvent.type)}</Typography>
                            <Typography variant="body2">
                              <FormattedDate value={licensingProcess.latestEvent.createdAt} />
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}

            {licensingProcesses && (
              <Grid container item justifyContent="space-between" alignItems="baseline" spacing={1}>
                <Grid item>
                  <Paper className={classes.paginationInfo}>
                    <Typography variant="subtitle2">
                      Mostrando {licensingProcesses.page.number * licensingProcesses.page.size + 1} até{' '}
                      {Math.min(
                        licensingProcesses.page.number * licensingProcesses.page.size + licensingProcesses.page.size,
                        licensingProcesses.page.totalElements
                      )}{' '}
                      de {licensingProcesses.page.totalElements} processos
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item>
                  <Paper>
                    <Button disabled={licensingProcesses.page.number === 0} onClick={this.firstPage}>
                      <FirstPageIcon />
                      Primeira
                    </Button>
                    <Button disabled={licensingProcesses.page.number === 0} onClick={this.prevPage}>
                      <PrevPageIcon />
                      Anterior
                    </Button>
                    <Button
                      disabled={licensingProcesses.page.number + 1 >= licensingProcesses.page.totalPages}
                      onClick={this.nextPage}
                    >
                      Próxima
                      <NextPageIcon />
                    </Button>

                    <Button
                      disabled={licensingProcesses.page.number + 1 >= licensingProcesses.page.totalPages}
                      onClick={this.lastPage}
                    >
                      Última
                      <LastPageIcon />
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </MainLayout>
    );
  }
}

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  paginationInfo: {
    padding: '9px 10px 10px 10px',
  },
});

export default withTranslation()(withStyles(styles)(LicensingProcessList));
