import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MaskedInput from 'react-text-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { MySnackbarContentWrapper as SnackbarContent } from '../../components/CSnackbar';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

const toNumber = (value) => parseFloat(value.replace(/\./g, '').replace(/,/g, '.'));

const f = (value) => {
  if (value == null) {
    return '';
  }
  if (Number.isNaN(value)) {
    return '';
  }
  if (typeof value === 'number') {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return value;
};

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;
  const numberMask = createNumberMask({
    allowDecimal: true,
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
    prefix: '',
  });
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberMask}
    />
  );
};

const decimalEqualOrGreaterThanZero = Yup.number()
  .transform((currentValue, originalValue) => toNumber(originalValue))
  .min(0, 'Informe um valor igual ou maior que zero')
  .typeError('Informe um valor numérico');

const validationSchema = (process) => () =>
  Yup.object()
    .shape({
      homologatedArea: decimalEqualOrGreaterThanZero,
      approvedArea: decimalEqualOrGreaterThanZero.max(
        Yup.ref('homologatedArea'),
        'Deve ser menor ou igual área homologada'
      ),
      receivedSeedAmount: decimalEqualOrGreaterThanZero,
      processedSeedAmount: decimalEqualOrGreaterThanZero.max(
        Yup.ref('receivedSeedAmount'),
        'Deve ser menor ou igual semente bruta'
      ),
      approvedSeedAmount: decimalEqualOrGreaterThanZero.max(
        Yup.ref('processedSeedAmount'),
        'Deve ser menor ou igual semente beneficiada'
      ),
      ownUseSeedAmount: decimalEqualOrGreaterThanZero.max(
        Yup.ref('approvedSeedAmount'),
        'Deve ser menor ou igual semente aprovada'
      ),
      commercializedSeedAmount: decimalEqualOrGreaterThanZero.max(
        Yup.ref('approvedSeedAmount'),
        'Deve ser menor ou igual semente aprovada'
      ),
      commercializedSeedValue: decimalEqualOrGreaterThanZero,
    })
    .test('royaltiesSeedAmount', function test(values) {
      const approved = Math.trunc((values.approvedSeedAmount || 0) * 100);
      const consumed = Math.trunc(((values.ownUseSeedAmount || 0) + (values.commercializedSeedAmount || 0)) * 100);
      const valid = approved >= consumed;
      return (
        valid ||
        this.createError({
          path: 'royaltiesSeedAmount',
          message:
            'A soma de semente para uso próprio e semente comercializada deve ser menor ou igual semente aprovada',
        })
      );
    })
    .test('receivedSeedAmount', function test(values) {
      const received = Math.trunc((values.receivedSeedAmount || 0) * 100);
      const approvedMaxRange = Math.trunc((values.approvedArea || 0) * 500);
      const valid = received <= approvedMaxRange;
      return (
        valid ||
        this.createError({
          path: 'receivedSeedAmount',
          message: 'Deve ser em toneladas',
        })
      );
    });

export default function YieldPhaseRespond({ onEvent, process, user }) {
  if (user.isProducer && process.canUpdateYieldInformation()) {
    const event = process.getLatestYieldInformationUpdateEvent();
    return (
      <Formik
        initialValues={{
          homologatedArea: f(process.homologatedArea),
          approvedArea: f(process.approvedArea),
          receivedSeedAmount: f(process.receivedSeedAmount),
          processedSeedAmount: f(process.processedSeedAmount),
          approvedSeedAmount: f(process.approvedSeedAmount),
          ownUseSeedAmount: f(process.ownUseSeedAmount),
          commercializedSeedAmount: f(process.commercializedSeedAmount),
          commercializedSeedValue: f(process.commercializedSeedValue),
        }}
        enableReinitialize
        validationSchema={validationSchema(process)}
        onSubmit={async (values, actions) => {
          await onEvent(async () => {
            try {
              const payload = Object.entries(values).reduce((obj, [key, value]) => {
                obj[key] = toNumber(value);
                return obj;
              }, {});
              await process.updateYieldInformation(payload);
              actions.setStatus({
                success: 'Informação de produção atualizada!',
              });
              actions.setSubmitting(false);
            } catch (error) {
              console.log(error);
              actions.setSubmitting(false);
              actions.setStatus({
                error: 'Não foi possível atualizar a informação de produção!',
              });
            }
          });
        }}
      >
        {({ values, errors, status, setStatus, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={status && status.error !== undefined}
              onClose={() => setStatus({ error: undefined })}
            >
              <SnackbarContent
                onClose={() => setStatus({ error: undefined })}
                variant="error"
                message={status && status.error}
              />
            </Snackbar>
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={status && status.success !== undefined}
              onClose={() => setStatus({ success: undefined })}
            >
              <SnackbarContent
                onClose={() => setStatus({ success: undefined })}
                variant="success"
                message={status && status.success}
              />
            </Snackbar>
            {event && (
              <Typography gutterBottom>
                {user.email === event.userId ? (
                  'Você'
                ) : (
                  <React.Fragment>
                    <strong>{event.user}</strong> da <strong>{event.company}</strong>
                  </React.Fragment>
                )}{' '}
                <EventMessage active event={event} process={process} /> em{' '}
                <strong>
                  <FormattedDate value={event.createdAt} />
                </strong>
                .
              </Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="homologatedArea"
                  name="homologatedArea"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.homologatedArea}
                  label="Área homologada"
                  disabled={process.isFieldReadOnly('homologatedArea')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <InputAdornment position="start">ha</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={touched.homologatedArea && errors.homologatedArea}
                  error={!!errors.homologatedArea && !!touched.homologatedArea}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="approvedArea"
                  name="approvedArea"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.approvedArea}
                  label="Área aprovada"
                  disabled={process.isFieldReadOnly('approvedArea')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <InputAdornment position="start">ha</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={touched.approvedArea && errors.approvedArea}
                  error={!!errors.approvedArea && !!touched.approvedArea}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="receivedSeedAmount"
                  name="receivedSeedAmount"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.receivedSeedAmount}
                  label="Semente bruta"
                  disabled={process.isFieldReadOnly('receivedSeedAmount')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={touched.receivedSeedAmount && errors.receivedSeedAmount}
                  error={!!errors.receivedSeedAmount && !!touched.receivedSeedAmount}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="processedSeedAmount"
                  name="processedSeedAmount"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.processedSeedAmount}
                  label="Semente beneficiada"
                  disabled={process.isFieldReadOnly('processedSeedAmount')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={touched.processedSeedAmount && errors.processedSeedAmount}
                  error={!!errors.processedSeedAmount && !!touched.processedSeedAmount}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="approvedSeedAmount"
                  name="approvedSeedAmount"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.approvedSeedAmount}
                  label="Semente aprovada"
                  disabled={process.isFieldReadOnly('approvedSeedAmount')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={touched.approvedSeedAmount && errors.approvedSeedAmount}
                  error={!!errors.approvedSeedAmount && !!touched.approvedSeedAmount}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="ownUseSeedAmount"
                  name="ownUseSeedAmount"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ownUseSeedAmount}
                  label="Semente para uso próprio"
                  disabled={process.isFieldReadOnly('ownUseSeedAmount')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={touched.ownUseSeedAmount && (errors.ownUseSeedAmount || errors.royaltiesSeedAmount)}
                  error={(!!errors.ownUseSeedAmount || !!errors.royaltiesSeedAmount) && !!touched.ownUseSeedAmount}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="commercializedSeedAmount"
                  name="commercializedSeedAmount"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.commercializedSeedAmount}
                  disabled={process.isFieldReadOnly('commercializedSeedAmount')}
                  label="Semente comercializada"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={
                    touched.commercializedSeedAmount && (errors.commercializedSeedAmount || errors.royaltiesSeedAmount)
                  }
                  error={
                    (!!errors.commercializedSeedAmount || !!errors.royaltiesSeedAmount) &&
                    !!touched.commercializedSeedAmount
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="commercializedSeedValue"
                  name="commercializedSeedValue"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.commercializedSeedValue}
                  label="Valor total comercializado"
                  disabled={process.isFieldReadOnly('commercializedSeedValue')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  helperText={touched.commercializedSeedValue && errors.commercializedSeedValue}
                  error={!!errors.commercializedSeedValue && !!touched.commercializedSeedValue}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || process.deleted}
                  fullWidth
                  onClick={handleSubmit}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    );
  }
  return null;
}
