import React, { useState } from 'react';
import { Dialog, Link, IconButton, Typography, AppBar, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/CloseRounded';
import LicensingProcessAttachment from '../api/LicensingProcessAttachment';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  link: {
    verticalAlign: 'baseline',
  },
}));

export default function DownloadAttachmentLink({ children, id, process }) {
  const classes = useStyles();
  const [attachment, setAttachment] = useState(null);

  const handleClose = () => {
    setAttachment(null);
  };

  return (
    <>
      <Link
        variant="body2"
        underline="hover"
        component="button"
        classes={{
          button: classes.link,
        }}
        onClick={async () => {
          const attachment = await LicensingProcessAttachment.get(id);
          if (attachment.name.endsWith('.pdf')) {
            setAttachment(attachment);
          } else {
            const link = document.createElement('a');
            link.href = attachment._links.s3Link.href;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }}
      >
        {children}
      </Link>
      <Dialog open={!!attachment} fullWidth={true} maxWidth={'lg'} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Processo #{process?.id}
            </Typography>
          </Toolbar>
        </AppBar>
        <iframe
          title={attachment?.name}
          src={attachment?._links.s3Link.href}
          type="application/pdf"
          style={{
            height: '100vh',
            border: 0,
          }}
        />
      </Dialog>
    </>
  );
}
