import React from 'react';

export const LoadingContext = React.createContext({
  loading: false,
  startLoading: () => {},
  stopLoading: () => {},
  user: undefined,
});

export function withLoading(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <LoadingContext.Consumer>
          {(context) => {
            return <WrappedComponent {...this.props} {...context} />;
          }}
        </LoadingContext.Consumer>
      );
    }
  };
}
