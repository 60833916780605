import axios from 'axios';

export default class LicensingProcessReport {
  static async create(filters) {
    try {
      const response = await axios.get('/licensingProcessReports', {
        params: {
          ...filters,
        },
      });
      return response.data.link;
    } catch (error) {
      console.log(error, error.response);
    }
  }
}
