import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

export default function FieldsPhaseRespondAwait({ process, user }) {
  if ((user.isBreeder || user.isBreederViewer || user.isLicenser) && process.canSendFieldRegistrationConfirmation()) {
    const issueEvent = process.getIssueFieldRegistrationAuthorizationEvent();
    const sendEvents = process.getSendFieldRegistrationConfirmationEvents();
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom>
            {user.email === issueEvent.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{issueEvent.user}</strong> da <strong>{issueEvent.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={issueEvent} process={process} /> em{' '}
            <strong>
              <FormattedDate value={issueEvent.createdAt} />
            </strong>
            .
          </Typography>
          {sendEvents.length === 0 && (
            <Typography>
              A homologação dos campos será enviada pelo produtor <strong>{process.producer.name}</strong> em breve, por
              favor aguarde.
            </Typography>
          )}
          {sendEvents.map((sendEvent) => (
            <Typography key={sendEvent.createdAt}>
              {user.email === sendEvent.userId ? (
                'Você'
              ) : (
                <React.Fragment>
                  <strong>{sendEvent.user}</strong> da <strong>{sendEvent.company}</strong>
                </React.Fragment>
              )}{' '}
              <EventMessage active event={sendEvent} process={process} /> em{' '}
              <strong>
                <FormattedDate value={sendEvent.createdAt} />
              </strong>
              .
            </Typography>
          ))}
        </Grid>
      </Grid>
    );
  }
  return null;
}
