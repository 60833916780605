import React, { useState, useEffect, useContext } from 'react';
import { LoadingContext } from '../../../LoadingContext';
import ProducerProfile from '../../../api/ProducerProfile';
import { MainLayout } from '../../../layouts';
import { Grid } from '@material-ui/core';
import LicensingProcessesCard from './LicensingProcessesCard';
import UsersCard from './UsersCard';
import ProducerCard from './ProducerCard';

export default function Detail({ producerId, nav }) {
  const [, setError] = useState(null);
  const [producer, setProducer] = useState({ name: '' });
  const { startLoading, stopLoading } = useContext(LoadingContext);
  useEffect(() => {
    async function getDetail() {
      startLoading();
      try {
        const producer = await ProducerProfile.get(producerId);
        setProducer(producer);
      } catch (e) {
        setError(e);
      }
      stopLoading();
    }
    getDetail();
  }, [startLoading, stopLoading, producerId]);
  return (
    <MainLayout
      nav={nav}
      title={producer.name}
      ready={!!producer.name}
      breadcrumbs={[
        { path: '/', label: 'Início' },
        { path: '/produtores', label: 'Produtores' },
        { path: `/${producer.textId}`, label: producer.name },
      ]}
    >
      <Grid container item direction="row" spacing={3} alignItems="flex-start">
        <Grid container item xs={12} sm={6}>
          <Grid container item direction="column" spacing={3}>
            <Grid item xs>
              <ProducerCard producer={producer} />
            </Grid>
            <Grid item xs>
              <UsersCard users={producer.users} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6}>
          <Grid item xs>
            <LicensingProcessesCard licensingProcesses={producer.licensingProcesses} />
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
