import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography, withStyles } from '@material-ui/core';
import DialogLayout from '../layouts/Dialog';
import cadastro from '../images/tutorial/cadastro.png';
import login from '../images/tutorial/login.png';
import paginaInicial from '../images/tutorial/pagina_inicial.png';
import listaDeCultivares from '../images/tutorial/lista_de_cultivares.png';
import detalheDaCultivar from '../images/tutorial/detalhe_da_cultivar.png';
import formularioDeSolicitacao from '../images/tutorial/formulario_de_solicitacao.png';
import aguardandoAnaliseDaSolicitacao from '../images/tutorial/aguardando_analise_da_solicitacao.png';
import assinarContrato from '../images/tutorial/assinar_o_contrato.png';
import enviarNumeroDaHomologacaoDeCampo from '../images/tutorial/enviar_o_numero_da_homologacao_de_campo.png';
import atualizarInformacaoDeProducao from '../images/tutorial/atualizar_informacao_de_producao.png';

const styles = (theme) => ({
  image: {
    display: 'block',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
    '@media print': {
      breakBefore: 'avoid',
    },
  },
  header: {
    marginTop: theme.spacing(4),
    '@media print': {
      breakBefore: 'page',
    },
  },
});

export default withStyles(styles)(({ classes }) => (
  <DialogLayout title="Tutorial do Produtor">
    <Grid>
      <ul>
        <li>
          <Typography>
            <a href="/#/tutorial/realizar-o-cadastro">Realizar o Cadastro</a>
          </Typography>
        </li>
        <li>
          <Typography>
            <a href="/#/tutorial/enviar-a-solicitacao-para-licenciamento">Enviar a Solicitação para Licenciamento</a>
          </Typography>
        </li>
        <li>
          <Typography>
            <a href="/#/tutorial/assinar-o-contrato">Assinar o Contrato</a>
          </Typography>
        </li>
        <li>
          <Typography>
            <a href="/#/tutorial/enviar-o-numero-da-homologacao-de-campo">Enviar o Número da Homologação de Campo</a>
          </Typography>
        </li>
        <li>
          <Typography>
            <a href="/#/tutorial/atualizar-a-informacao-de-producao">Atualizar a Informação de Produção</a>
          </Typography>
        </li>
      </ul>
      <Typography component="h2" variant="h6" id="/tutorial/realizar-o-cadastro">
        <strong>Realizar o Cadastro</strong>
      </Typography>
      <Typography variant="body2">
        Caso ainda não tenha, o primeiro passo para licenciar uma cultivar através da NetSeeds é fazer o seu cadastro
        como produtor. Se já tiver realizado o cadastro, pule este passo. Caso contrário,{' '}
        <Link to="/cadastro">preencha o formulário</Link> e clique no botão <strong>Enviar</strong>:
      </Typography>
      <Typography variant="body2">
        <img alt="Cadastro" className={classes.image} src={cadastro} />
      </Typography>
      <Typography
        id="/tutorial/enviar-a-solicitacao-para-licenciamento"
        component="h2"
        variant="h6"
        className={classes.header}
      >
        Enviar a Solicitação para Licenciamento
      </Typography>
      <Typography variant="body2">
        Acesse a <Link to="/">página inicial</Link> e clique no botão <strong>Comece por aqui</strong>:
      </Typography>
      <img alt="Página Inicial" className={classes.image} src={paginaInicial} />
      <Typography variant="body2">
        Você terá acesso a lista de todas as cultivares disponíveis para licenciamento pela NetSeeds. Para iniciar o
        licenciamento, clique sobre a cultivar escolhida:
      </Typography>
      <img alt="Lista de Cultivares" className={classes.image} src={listaDeCultivares} />
      <Typography variant="body2">
        Na página da cultivar você poderá ter informações sobre a cultivar. Clique em{' '}
        <strong>Licenciar esta cultivar</strong> para dar prosseguimento:
      </Typography>
      <img alt="Detalhe da Cultivar" className={classes.image} src={detalheDaCultivar} />
      <Typography variant="body2">
        Nesse momento você terá que se identificar, use o seu e-mail e a sua senha para preencher o formulário e clique
        em <strong>Enviar</strong>:
      </Typography>
      <img alt="Entrar" className={classes.image} src={login} />
      <Typography variant="body2">
        Após o login você será redirecionado para o formulário de solicitação de licenciamento. Informe o número de
        hectares que pretende plantar, a categoria de sementes, anexe um documento indicando qual é a origem das
        sementes que serão usadas (por exemplo, nota fiscal, certificado ou termo de conformidade), aceite os termos e
        condições e por fim clique em <strong>Enviar</strong>. Você terá que fazer uma solicitação individual para cada
        combinação de cultivar e categoria que desejar licenciar em uma safra. Caso tenha mais de um documento
        comprovando a origem das sementes, combine os documentos em apenas um arquivo.
      </Typography>
      <img alt="Formulário de Solicitação" className={classes.image} src={formularioDeSolicitacao} />
      <Typography variant="body2">
        Ao clicar no botão a NetSeeds irá notificar o Licenciador sobre a sua solicitação e você receberá uma
        confirmação do envio da solicitação. Você poderá acompanhar a sua solicitação a partir da página do processo:
      </Typography>
      <img alt="Aguardando Análise da Solicitação" className={classes.image} src={aguardandoAnaliseDaSolicitacao} />
      <Typography variant="body2">
        Assim que o licenciador responder a sua solicitação, a NetSeeds enviará um e-mail para você avisando sobre o
        andamento do processo.
      </Typography>
      <Typography component="h2" variant="h6" id="/tutorial/assinar-o-contrato" className={classes.header}>
        Assinar o Contrato
      </Typography>
      <Typography variant="body2">
        O próximo passo no processo de licenciamento é a assinatura do contrato. Consulte o contrato na seção Documentos
        e depois clique em <strong>Assinar Contrato</strong>:
      </Typography>
      <img alt="Assinar o Contrato" className={classes.image} src={assinarContrato} />
      <Typography
        component="h2"
        variant="h6"
        id="/tutorial/enviar-o-numero-da-homologacao-de-campo"
        className={classes.header}
      >
        Enviar o Número da Homologação de Campo
      </Typography>
      <Typography variant="body2">
        O próximo passo no processo de licenciamento é o envio do número da homologação de campo no SIGEF. Informe o
        número da homologação (por exemplo '5iy1jxnwu6cc') e clique em <strong>Enviar</strong>. Caso possua mais de um
        campo, envie um de cada vez.
      </Typography>
      <img
        alt="Enviar Número da Homologação de Campo"
        className={classes.image}
        src={enviarNumeroDaHomologacaoDeCampo}
      />
      <Typography
        component="h2"
        variant="h6"
        id="/tutorial/atualizar-a-informacao-de-producao"
        className={classes.header}
      >
        Atualizar a Informação de Produção
      </Typography>
      <Typography variant="body2">
        A seguir será possível informar o resultado da produção de sementes e também as informações de comercialização.
        Agora informe os valores à medida que as informações estiverem disponíveis e clique em <strong>Enviar</strong>.
      </Typography>
      <img alt="Atualizar Informação de Produção" className={classes.image} src={atualizarInformacaoDeProducao} />
      <Typography variant="body2">
        Caso tenham ficado dúvidas, não hesite em entrar em contato pelo e-mail{' '}
        <a href="mailto:romulo@netseeds.com.br">romulo@netseeds.com.br</a>.
      </Typography>
    </Grid>
  </DialogLayout>
));
