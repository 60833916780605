import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Snackbar,
  TextField,
  Typography,
  withStyles,
  FormHelperText,
} from '@material-ui/core';
import { MySnackbarContentWrapper as SnackbarContent } from '../../components/CSnackbar';
import TermsAndConditions from './TermsAndConditions';

const validationSchema = Yup.object().shape({
  area: Yup.number()
    .positive('Informe um valor inteiro maior que zero')
    .integer('Informe um valor inteiro maior que zero')
    .required('Informe um valor inteiro maior que zero'),
  seedCategory: Yup.string().required('Escolha uma categoria'),
  sourceDocName: Yup.string().required('Escolha um documento'),
  sourceDocSize: Yup.number().max(5242880, 'Arquivo deve ter no máximo 5mb'),
  agreedToTermsAndConditions: Yup.boolean()
    .oneOf([true], 'Concorde com os termos e condições')
    .required('Concorde com os termos e condições'),
});

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit,
  },
  termsAndConditionsError: {
    margin: 0,
  },
  error: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class Licensing extends React.Component {
  state = {
    error: false,
    licensingProcessId: undefined,
    success: false,
    termsAndConditionsOpen: false,
  };

  handleCloseSuccessSnackbar = () => {
    this.props.history.push(`/licenciamento/processos/${this.state.licensingProcessId}`);
  };

  handleCloseErrorSnackbar = () => {
    this.setState({ error: undefined });
  };

  render() {
    const { classes, cultivar, history, onEvent } = this.props;
    const { error, errorMessage, licensingProcessId, success, termsAndConditionsOpen } = this.state;
    const requestToProduce = {
      area: '',
      seedCategory: '',
      sourceDocName: '',
      agreedToTermsAndConditions: false,
    };
    const showTermsAndConditions = (event) => {
      event.preventDefault();
      this.setState({ termsAndConditionsOpen: true });
    };
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={success === true}
          autoHideDuration={5000}
          onClose={this.handleCloseSuccessSnackbar}
        >
          <SnackbarContent
            onClose={this.handleCloseSuccessSnackbar}
            variant="success"
            message={`A solicitação #${licensingProcessId} foi registrada com sucesso! Aguarde...`}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error === true}
          onClose={this.handleCloseErrorSnackbar}
        >
          <SnackbarContent
            onClose={this.handleCloseErrorSnackbar}
            variant="error"
            message={errorMessage || 'Não foi possível enviar a solicitação!'}
          />
        </Snackbar>
        <Formik
          initialValues={requestToProduce}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            await onEvent(async () => {
              try {
                const fd = new FormData();
                fd.append('cultivar', cultivar.id);
                fd.append('seedCategory', values.seedCategory);
                fd.append('area', values.area);
                fd.append('sourceDoc', values.sourceDoc);
                const response = await axios.post('/licensingProcesses', fd);
                const licensingProcessId = response.headers.location.split('/').pop();
                this.setState({ success: true, licensingProcessId });
              } catch (error) {
                let errorMessage = null;
                if (error.response.data.errors && error.response.data.errors.length) {
                  errorMessage = error.response.data.errors[0];
                }
                this.setState({ error: true, errorMessage });
              }
            });
            actions.setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <Dialog open onClose={history.goBack} scroll="paper" aria-labelledby="scroll-dialog-title">
                <DialogTitle id="scroll-dialog-title">
                  Licenciar Cultivar <strong>{cultivar.name}</strong>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Preencha o formulário abaixo e envie a solicitação para licenciar a produção de sementes da
                    cultivar. O licenciador <strong>{cultivar.licenser.name}</strong> irá analisar a sua solicitação e
                    em breve você receberá a resposta para dar andamento ao processo.
                  </DialogContentText>

                  <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <TermsAndConditions
                      open={termsAndConditionsOpen}
                      onAgree={() => {
                        setFieldValue('agreedToTermsAndConditions', true);
                        this.setState({ termsAndConditionsOpen: false });
                      }}
                      onDisagree={() => {
                        setFieldValue('agreedToTermsAndConditions', false);
                        this.setState({ termsAndConditionsOpen: false });
                      }}
                    />
                    <TextField
                      id="area"
                      name="area"
                      type="number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.area}
                      label="Área a ser plantada"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" variant="filled">
                            ha
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="normal"
                      required
                      fullWidth
                      variant="outlined"
                      helperText={touched.area && errors.area}
                      error={!!errors.area && !!touched.area}
                    />
                    <TextField
                      id="seedCategory"
                      name="seedCategory"
                      select
                      label="Categoria de semente a ser produzida"
                      value={values.seedCategory}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      helperText={touched.seedCategory && errors.seedCategory}
                      error={!!errors.seedCategory && !!touched.seedCategory}
                    >
                      <MenuItem value="BASIC">Básica</MenuItem>
                      <MenuItem value="C1">C1</MenuItem>
                      <MenuItem value="C2">C2</MenuItem>
                      <MenuItem value="S1">S1</MenuItem>
                      <MenuItem value="S2">S2</MenuItem>
                    </TextField>
                    <TextField
                      id="sourceDocName"
                      name="sourceDocName"
                      label="Documento indicando a origem da semente"
                      value={values.sourceDocName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      required
                      variant="outlined"
                      margin="normal"
                      helperText={
                        (touched.sourceDocName && errors.sourceDocName) ||
                        (touched.sourceDocSize && errors.sourceDocSize) ||
                        'Nota fiscal, certificado ou termo de conformidade indicando a origem da semente. Caso tenha mais de um documento, combine em apenas um arquivo. O arquivo deve ter no máximo 5mb.'
                      }
                      error={
                        (!!errors.sourceDocName && !!touched.sourceDocName) ||
                        (!!errors.sourceDocSize && !!touched.sourceDocSize)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment variant="filled" position="end">
                            <Button
                              variant="contained"
                              disableRipple
                              component="label"
                              disabled={isSubmitting || success}
                            >
                              Escolher
                              <input
                                id="sourceDoc"
                                name="sourceDoc"
                                type="file"
                                onChange={(event) => {
                                  const file = event.target.files[0];
                                  if (file) {
                                    setFieldValue('sourceDoc', file);
                                    setFieldValue('sourceDocName', file.name);
                                    setFieldValue('sourceDocSize', file.size);
                                    setFieldTouched('sourceDocSize', true);
                                  }
                                }}
                                style={{ display: 'none' }}
                              />
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <FormControl
                      error={!!touched.agreedToTermsAndConditions && !!errors.agreedToTermsAndConditions}
                      required
                      fullWidth
                      variant="outlined"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="agreedToTermsAndConditions"
                            name="agreedToTermsAndConditions"
                            checked={values.agreedToTermsAndConditions === true}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value="accepted"
                            color="primary"
                          />
                        }
                        label={
                          <Typography component="p">
                            Estou de acordo com os{' '}
                            <Link to={`/cultivares/${cultivar.id}/licenciar/termos`} onClick={showTermsAndConditions}>
                              termos e condições
                            </Link>
                          </Typography>
                        }
                      />

                      {touched.agreedToTermsAndConditions && errors.agreedToTermsAndConditions && (
                        <FormHelperText className={classes.termsAndConditionsError}>
                          {errors.agreedToTermsAndConditions}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {status && status.message && (
                      <Paper className={classes.error}>
                        <Typography align="center" component="h6" color="error">
                          {status.message}
                        </Typography>
                      </Paper>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting || success}
                    >
                      Enviar
                    </Button>
                  </form>
                </DialogContent>
                <DialogActions></DialogActions>
              </Dialog>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Licensing);
