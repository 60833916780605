import React from 'react';
import Typography from '@material-ui/core/Typography';
import DownloadAttachmentLink from '../../components/DownloadAttachmentLink';
import FormattedDate from '../../components/FormattedDate';

export const AttachmentsContent = ({ process, user }) => (
  <React.Fragment>
    {process.attachments.map((attachment) => (
      <React.Fragment key={attachment.createdAt}>
        <Typography variant="caption">
          <FormattedDate value={attachment.createdAt} />
        </Typography>
        <Typography gutterBottom>
          <DownloadAttachmentLink id={attachment.id} process={process}>
            {attachment.name}
          </DownloadAttachmentLink>
        </Typography>
      </React.Fragment>
    ))}
  </React.Fragment>
);
