import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PrivateRoute from '../../PrivateRoute';
import CultivarAPI from '../../api/Cultivar';
import { MainLayout } from '../../layouts';
import { LoadingContext } from '../../LoadingContext';
import Info from './Info';
import Licensing from './Licensing';

export default class CultivarDetail extends React.Component {
  static contextType = LoadingContext;

  state = {
    cultivar: { id: '', name: '' },
    ready: false,
  };

  async componentDidMount() {
    const { startLoading, stopLoading } = this.context;
    const id = this.props.match.params.id;
    startLoading();
    const cultivar = await CultivarAPI.get(id);
    stopLoading();
    const ready = true;
    this.setState({ cultivar, ready });
  }

  wrapCallbackLoading = async (cb) => {
    const { startLoading, stopLoading } = this.context;
    startLoading();
    await cb();
    stopLoading();
  };

  render() {
    const { nav } = this.props;
    const { cultivar, ready } = this.state;
    return (
      <MainLayout
        nav={nav}
        title={`Cultivar ${cultivar.name}`}
        actions={
          <Button
            component={Link}
            variant="outlined"
            to={`/cultivares/${cultivar.id}/licenciar`}
            disabled={!cultivar.enabled}
          >
            Licenciar esta cultivar
          </Button>
        }
        ready={ready}
        breadcrumbs={[
          { path: '/', label: 'Início' },
          { path: '/cultivares', label: 'Cultivares' },
          {
            path: `/cultivares/${cultivar.id}`,
            label: cultivar.name,
          },
        ]}
      >
        <Switch>
          <Route path="/cultivares/:id" exact render={() => <Info cultivar={cultivar} />} />

          <PrivateRoute
            path="/cultivares/:id/licenciar"
            render={(props) => (
              <React.Fragment>
                <Info cultivar={cultivar} />
                {cultivar.enabled && <Licensing {...props} onEvent={this.wrapCallbackLoading} cultivar={cultivar} />}
              </React.Fragment>
            )}
          />
        </Switch>
      </MainLayout>
    );
  }
}
