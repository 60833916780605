import React from 'react';
import { Card, CardContent, CardHeader, Grid, Divider, Typography, Link } from '@material-ui/core';
import FormattedDate from '../../../components/FormattedDate';

function UserDetails({ user }) {
  return (
    <Grid item xs={12}>
      <Divider />
      <CardContent>
        <Grid container item spacing={1}>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Nome
            </Typography>
            <Typography variant="subtitle2">{user.displayName}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" color="textSecondary">
              E-mail
            </Typography>
            <Link href={`mailto:${user.email}`} variant="subtitle2">
              {user.email}
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2" color="textSecondary">
              Situação
            </Typography>
            <Typography variant="subtitle2">{user.enabled === true ? 'Ativo' : 'Inativo'}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body2" color="textSecondary">
              Data do último acesso
            </Typography>
            <Typography variant="subtitle2">
              <FormattedDate value={user.lastAccessAt} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Grid>
  );
}

export default function UsersCard({ users = [] }) {
  return (
    <Card>
      <CardHeader title="Usuários" />
      <Grid container>
        {users.map((user) => (
          <UserDetails key={user.email} user={user} />
        ))}
        {users.length === 0 && (
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              Este produtor não tem usuários cadastrados
            </Typography>
          </CardContent>
        )}
      </Grid>
    </Card>
  );
}
