import React from 'react';
import { Button, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MySnackbarContentWrapper as SnackbarContent } from '../../components/CSnackbar';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

const styles = (theme) => ({
  expansionPanelRoot: {
    '&::before': {
      height: 0,
    },
  },
  expansionPanelExpanded: {
    margin: 0,
  },
  expansionPanelSummaryRoot: {
    padding: 0,
    '&$expansionPanelSummaryExpanded': {
      minHeight: '48px',
    },
  },
  expansionPanelSummaryContent: {
    '&$expansionPanelSummaryExpanded': {
      margin: 0,
    },
  },
  expansionPanelSummaryExpanded: {
    margin: 0,
  },
  expansionPanelSummaryExpandIcon: {
    right: 0,
  },
  expansionPanelDetailsRoot: {
    padding: 0,
  },
});

const validationSchema = Yup.object().shape({
  fieldRegistrationId: Yup.string()
    .required('Informe um valor')
    .min(12, 'Informe um valor com 12 caracteres')
    .max(12, 'Informe um valor com 12 caracteres'),
});

const Form = ({ onEvent, process }) => (
  <Formik
    initialValues={{ fieldRegistrationId: '' }}
    enableReinitialize
    validationSchema={validationSchema}
    onSubmit={async (values, actions) => {
      await onEvent(async () => {
        try {
          await process.sendFieldRegistrationConfirmation({
            fieldRegistrationId: values.fieldRegistrationId,
          });
          values.fieldRegistrationId = '';
          actions.setStatus({ success: 'Campo validado!' });
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errors) {
            actions.setStatus({
              error: error.response.data.errors[0],
            });
          } else {
            actions.setStatus({
              error: 'Não foi possível validar o campo!',
            });
          }
        }
      });
      actions.setSubmitting(false);
    }}
  >
    {({ values, errors, status, setStatus, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={status && status.error !== undefined}
          onClose={() => setStatus({ error: undefined })}
        >
          <SnackbarContent
            onClose={() => setStatus({ error: undefined })}
            variant="error"
            message={status && status.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={status && status.success !== undefined}
          onClose={() => setStatus({ success: undefined })}
        >
          <SnackbarContent
            onClose={() => setStatus({ success: undefined })}
            variant="success"
            message={status && status.success}
          />
        </Snackbar>
        <TextField
          id="fieldRegistrationId"
          name="fieldRegistrationId"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.fieldRegistrationId}
          label="Identificador da inscrição do campo no SIGEF/MAPA"
          margin="normal"
          InputProps={{
            inputProps: {
              maxLength: 12,
            },
          }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          helperText={touched.fieldRegistrationId && errors.fieldRegistrationId}
          error={!!errors.fieldRegistrationId && !!touched.fieldRegistrationId}
        />
        <Button
          color="primary"
          disabled={isSubmitting || process.deleted}
          fullWidth
          onClick={handleSubmit}
          variant="contained"
        >
          Enviar
        </Button>
      </form>
    )}
  </Formik>
);

export default withStyles(styles)(({ classes, onEvent, process, user }) => {
  if (user.isProducer && process.canSendFieldRegistrationConfirmation()) {
    const event = process.getIssueFieldRegistrationAuthorizationEvent();
    const sendEvents = process.getSendFieldRegistrationConfirmationEvents();
    return (
      <Grid container spacing={3}>
        <Grid item>
          <Typography gutterBottom>
            {user.email === event.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{event.user}</strong> da <strong>{event.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={event} process={process} /> em{' '}
            <strong>
              <FormattedDate value={event.createdAt} />
            </strong>
            .
          </Typography>
          {sendEvents.map((sendEvent) => (
            <Typography key={sendEvent.createdAt}>
              {user.email === sendEvent.userId ? (
                'Você'
              ) : (
                <React.Fragment>
                  <strong>{sendEvent.user}</strong> da <strong>{sendEvent.company}</strong>
                </React.Fragment>
              )}{' '}
              <EventMessage active event={sendEvent} process={process} /> em{' '}
              <strong>
                <FormattedDate value={sendEvent.createdAt} />
              </strong>
              .
            </Typography>
          ))}
          {sendEvents.length === 0 && <Form onEvent={onEvent} process={process} />}
          {sendEvents.length > 0 && (
            <Accordion
              elevation={0}
              square
              classes={{
                root: classes.expansionPanelRoot,
                expanded: classes.expansionPanelExpanded,
              }}
            >
              <AccordionSummary
                classes={{
                  root: classes.expansionPanelSummaryRoot,
                  content: classes.expansionPanelSummaryContent,
                  expanded: classes.expansionPanelSummaryExpanded,
                  expandIcon: classes.expansionPanelSummaryExpandIcon,
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography variant="subtitle2">Enviar mais uma homologação de campo</Typography>
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.expansionPanelDetailsRoot,
                }}
              >
                <Form onEvent={onEvent} process={process} />
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      </Grid>
    );
  }
  return null;
});
