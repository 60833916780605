import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

export default function ContractPhaseRespondAwait({ process, user }) {
  if ((user.isBreeder || user.isBreederViewer || user.isLicenser) && process.canRespondToContract()) {
    const issueEvent = process.getIssueContractEvent();
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom>
            {user.email === issueEvent.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{issueEvent.user}</strong> da <strong>{issueEvent.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={issueEvent} process={process} /> em{' '}
            <strong>
              <FormattedDate value={issueEvent.createdAt} />
            </strong>
            .
          </Typography>
          <Typography>
            O contrato será analisado pelo produtor <strong>{process.producer.name}</strong> em breve, por favor
            aguarde.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
}
