import React from 'react';

import dayjs from 'dayjs';

export default function FormattedDate({ value, dateOnly = false }) {
  const format = dateOnly ? 'DD/MMMM/YYYY' : 'DD/MMMM/YYYY [às] HH:mm';
  if (value !== null && typeof value === 'string') {
    return <>{dayjs(value.replace('+0000', 'Z')).format(format)}</>;
  }
  if (value !== null && typeof value === 'object') {
    return <>{dayjs(value.toDate()).format(format)}</>;
  }
  return <>{'-'}</>;
}
