import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Snackbar, TextField, withStyles } from '@material-ui/core';
import Auth from '../auth';
import { DialogLayout } from '../layouts';
import { MySnackbarContentWrapper } from '../components/CSnackbar';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Informe um e-mail válido!').required('Informe o seu e-mail!'),
});

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class RecuperarSenha extends React.Component {
  state = { error: undefined, success: undefined };
  handleCloseSuccessSnackbar = () => {
    this.props.history.push('/');
  };
  handleCloseErrorSnackbar = () => {
    this.setState({ error: undefined });
  };
  render() {
    const { classes } = this.props;
    const { success, error } = this.state;
    return (
      <DialogLayout title="Recuperar a senha">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={success === true}
          autoHideDuration={3000}
          onClose={this.handleCloseSuccessSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSuccessSnackbar}
            variant="success"
            message="Um e-mail foi enviado com instruções para redefinir a senha!"
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error === true}
          onClose={this.handleCloseErrorSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseErrorSnackbar}
            variant="error"
            message="Não foi possível recuperar a senha!"
          />
        </Snackbar>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const result = await Auth.recoverPassword(values.email);
            if (result.success === true) {
              actions.setSubmitting(false);
              this.setState({ success: true });
            } else {
              actions.setSubmitting(false);
              this.setState({ error: true });
            }
          }}
        >
          {({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label="Informe o seu e-mail de login"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.email && errors.email}
                error={!!errors.email && !!touched.email}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting || success === true}
              >
                Enviar
              </Button>
            </form>
          )}
        </Formik>
      </DialogLayout>
    );
  }
}

export default withStyles(styles)(RecuperarSenha);
