import React from 'react';
import { Paper, Typography, withStyles } from '@material-ui/core';
import heroBackground from '../images/hero.jpg';
import n from '../images/favicon-master.png';

const styles = (theme) => ({
  '@global': {
    html: {
      height: '100%',
    },
    body: {
      height: '100%',
    },
    '#root': {
      background: `url(${heroBackground})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.down(400 + theme.spacing(3) * 2)]: {
        backgroundSize: 'cover',
      },
    },
  },
  layout: {
    alignItems: 'center',
    display: 'flex', // Fix IE 11 issue.
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    minHeight: '100vh',
    width: 'auto',
    [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
      width: 700,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
});

const DialogLayout = ({ classes, children, subTitle, title }) => (
  <main className={classes.layout}>
    <Paper className={classes.paper}>
      <div>
        <img src={n} alt="NetSeeds" style={{ marginRight: 10, verticalAlign: 'bottom', width: 35 }} />
        <Typography component="h1" variant="h5" display="inline">
          <strong>{title}</strong>
        </Typography>
      </div>
      {subTitle && <Typography component="p">{subTitle}</Typography>}
      {children}
    </Paper>
  </main>
);

export default withStyles(styles)(DialogLayout);
