import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import n from '../images/favicon-master.png';

const styles = (theme) => ({
  footer: {
    backgroundColor: grey[800],
    borderTop: `5px solid ${theme.palette.divider}`,
    color: grey[400],
    padding: `${theme.spacing(6)}px 0`,
  },
});

const Footer = ({ classes }) => (
  <footer className={classes.footer}>
    <Grid container justifyContent="center">
      <Grid item xs={9} sm={6}>
        <Typography align="center">
          <img src={n} alt="NetSeeds" style={{ width: 35 }} />
        </Typography>
        <Typography component="p" variant="subtitle2" align="center" color="inherit">
          NetSeeds
        </Typography>
        <Typography component="p" variant="body2" align="center" color="inherit">
          A maneira mais fácil de licenciar cultivares
        </Typography>
      </Grid>
    </Grid>
  </footer>
);

export default withStyles(styles)(Footer);
