import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

const f = (value) => {
  if (value == null) {
    return '';
  }
  if (Number.isNaN(value)) {
    return '';
  }
  if (typeof value === 'number') {
    return value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return value;
};

export default function YieldPhaseAwait({ process, user }) {
  if (user.isBreeder || user.isLicenser || user.isBreederViewer) {
    if (process.isBeyondYieldPhase()) {
      const event = process.getLatestYieldInformationUpdateEvent();
      return (
        <React.Fragment>
          <Typography gutterBottom>
            {user.email === event.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{event.user}</strong> da <strong>{event.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={event} process={process} /> em{' '}
            <strong>
              <FormattedDate value={event.createdAt} />
            </strong>
            .
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="homologatedArea"
                name="homologatedArea"
                type="text"
                value={f(process.homologatedArea)}
                label="Área homologada"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">ha</InputAdornment>,
                  readOnly: true,
                }}
                margin="dense"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="approvedArea"
                name="approvedArea"
                type="text"
                value={f(process.approvedArea)}
                label="Área aprovada"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">ha</InputAdornment>,
                  readOnly: true,
                }}
                margin="dense"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                id="receivedSeedAmount"
                name="receivedSeedAmount"
                type="text"
                value={f(process.receivedSeedAmount)}
                label="Semente bruta"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  readOnly: true,
                }}
                margin="dense"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="processedSeedAmount"
                name="processedSeedAmount"
                type="text"
                value={f(process.processedSeedAmount)}
                label="Semente beneficiada"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  readOnly: true,
                }}
                margin="dense"
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                id="approvedSeedAmount"
                name="approvedSeedAmount"
                type="text"
                value={f(process.approvedSeedAmount)}
                label="Semente aprovada"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="start">t</InputAdornment>,
                  readOnly: true,
                }}
                margin="dense"
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
          {!user.isBreederViewer && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="ownUseSeedAmount"
                  name="ownUseSeedAmount"
                  type="text"
                  value={f(process.ownUseSeedAmount)}
                  label="Semente para uso próprio"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">t</InputAdornment>,
                    readOnly: true,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="commercializedSeedAmount"
                  name="commercializedSeedAmount"
                  type="text"
                  value={f(process.commercializedSeedAmount)}
                  label="Semente comercializada"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">t</InputAdornment>,
                    readOnly: true,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="commercializedSeedValue"
                  name="commercializedSeedValue"
                  type="text"
                  value={f(process.commercializedSeedValue)}
                  label="Valor total comercializado"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    readOnly: true,
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          )}
        </React.Fragment>
      );
    }
    return (
      <Typography>
        A informação de produção será enviada pelo produtor <strong>{process.producer.name}</strong> em breve, por favor
        aguarde.
      </Typography>
    );
  }
  return null;
}
