import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Snackbar, TextField, withStyles } from '@material-ui/core';
import Auth from '../auth';
import { DialogLayout } from '../layouts';
import { MySnackbarContentWrapper } from '../components/CSnackbar';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Informe uma nova senha com no mínimo 8 caracteres!')
    .required('Informe uma nova senha com no mínimo 8 caracteres!'),
  confirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Informe novamente a sua nova senha!')
    .required('Informe novamente a sua nova senha!'),
});

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class RedefinirSenha extends React.Component {
  state = { error: undefined, success: undefined };
  handleCloseSuccessSnackbar = () => {
    this.props.history.push('/entrar');
  };
  handleCloseErrorSnackbar = () => {
    this.setState({ error: undefined });
  };
  render() {
    const { classes, match } = this.props;
    const { success, error } = this.state;
    const token = match.params.token;
    return (
      <DialogLayout title="Redefinir a senha">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={success === true}
          autoHideDuration={3000}
          onClose={this.handleCloseSuccessSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSuccessSnackbar}
            variant="success"
            message="A senha foi redefinida com sucesso! Aguarde..."
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error === true}
          onClose={this.handleCloseErrorSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseErrorSnackbar}
            variant="error"
            message="Não foi possível redefinir a senha!"
          />
        </Snackbar>
        <Formik
          initialValues={{ password: '', confirmation: '' }}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const result = await Auth.resetPassword(values.password, values.confirmation, token);
            if (result.success === true) {
              actions.setSubmitting(false);
              this.setState({ success: true });
            } else {
              actions.setSubmitting(false);
              this.setState({ error: true });
            }
          }}
        >
          {({ values, errors, status, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                id="password"
                name="password"
                type="password"
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label="Nova senha"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.password && errors.password}
                error={!!errors.password && !!touched.password}
              />
              <TextField
                id="confirmation"
                name="confirmation"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmation}
                label="Confirmação da nova senha"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.confirmation && errors.confirmation}
                error={!!errors.confirmation && !!touched.confirmation}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting || success === true}
              >
                Enviar
              </Button>
            </form>
          )}
        </Formik>
      </DialogLayout>
    );
  }
}

export default withStyles(styles)(RedefinirSenha);
