import { conformToMask } from 'text-mask-core';

const mask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

const format = (raw) => conformToMask(raw, mask).conformedValue;

const CPF = {
  mask,
  format,
};

export default CPF;
