import React from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { MixpanelContext } from '../../../MixpanelContext';
import * as YupValidation from '../../../shared/YupValidation';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import CompanyProfileAPI from '../../../api/CompanyProfile';
import { MySnackbarContentWrapper as SnackbarContent } from '../../../components/CSnackbar';
import {
  SimpleField,
  CNPJField,
  CPFField,
  TelephoneField,
  RenasemField,
  PostalCodeField,
  EmailField,
} from '../../../components/inputs';
import SectionHeader from '../../../components/SectionHeader';

const validationSchema = Yup.object().shape({
  countryTaxNumberType: YupValidation.countryTaxNumberType,
  name: YupValidation.name,
  legalName: YupValidation.legalName,
  cnpj: YupValidation.cnpj,
  cpf: YupValidation.cpf,
  ie: YupValidation.stateTaxNumber,
  renasem: YupValidation.renasem,
  legalRepresentative: YupValidation.legalRepresentative,
  address: YupValidation.address,
  phoneNumber: YupValidation.phoneNumber,
  technician: YupValidation.technician,
  financeContact: YupValidation.financeContact,
});

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class ProducerProfileForm extends React.Component {
  static contextType = MixpanelContext;
  state = {
    error: false,
    success: false,
  };

  handleCloseSuccessSnackbar = () => {
    this.setState({ success: undefined });
  };
  handleCloseErrorSnackbar = () => {
    this.setState({ error: undefined });
  };

  render() {
    const { classes, companyProfile, history, onEvent } = this.props;
    const { error, success } = this.state;
    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={success === true}
          autoHideDuration={2000}
          onClose={this.handleCloseSuccessSnackbar}
        >
          <SnackbarContent
            onClose={this.handleCloseSuccessSnackbar}
            variant="success"
            message="A atualização foi feita com sucesso!"
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error === true}
          onClose={this.handleCloseErrorSnackbar}
        >
          <SnackbarContent
            onClose={this.handleCloseErrorSnackbar}
            variant="error"
            message="Não foi possível efetivar a atualização!"
          />
        </Snackbar>
        <Dialog
          open
          fullWidth
          maxWidth="md"
          onClose={history.goBack}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Editar Cadastro</DialogTitle>
          <DialogContent>
            <DialogContentText />
            <Formik
              initialValues={{
                countryTaxNumberType: companyProfile.countryTaxNumberType,
                name: companyProfile.name ?? '',
                legalName: companyProfile.legalName ?? '',
                cpf: companyProfile.cpf ?? '',
                cnpj: companyProfile.cnpj ?? '',
                stateTaxNumber: companyProfile.stateTaxNumber ?? '',
                renasem: companyProfile.renasem ?? '',
                address: {
                  streetAddress: companyProfile.address?.streetAddress ?? '',
                  postalCode: companyProfile.address?.postalCode ?? '',
                  city: companyProfile.address?.city ?? '',
                  state: companyProfile.address?.state ?? '',
                },
                phoneNumber: companyProfile.phoneNumber ?? '',
                legalRepresentative: {
                  firstName: companyProfile.legalRepresentative?.firstName ?? '',
                  lastName: companyProfile.legalRepresentative?.lastName ?? '',
                  countryTaxNumber: companyProfile.legalRepresentative?.countryTaxNumber ?? '',
                },
                technician: {
                  firstName: companyProfile.technician?.firstName ?? '',
                  lastName: companyProfile.technician?.lastName ?? '',
                  countryTaxNumber: companyProfile.technician?.countryTaxNumber ?? '',
                  phoneNumber: companyProfile.technician?.phoneNumber ?? '',
                  email: companyProfile.technician?.email ?? '',
                  renasem: companyProfile.technician?.renasem ?? '',
                },
                financeContact: {
                  firstName: companyProfile.financeContact?.firstName ?? '',
                  lastName: companyProfile.financeContact?.lastName ?? '',
                  phoneNumber: companyProfile.financeContact?.phoneNumber ?? '',
                  email: companyProfile.financeContact?.email ?? '',
                },
              }}
              validateOnBlur
              validateOnChange={false}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                await onEvent(async () => {
                  try {
                    this.setState({ success: false });
                    if (values.countryTaxNumberType === 'cpf') {
                      delete values.legalRepresentative;
                    }
                    await CompanyProfileAPI.save(companyProfile.textId, values);
                    this.context.track('updateCompanyProfile', { result: 'success' });
                    this.setState({ success: true });
                  } catch (error) {
                    this.context.track('updateCompanyProfile', { result: 'failure' });
                    this.setState({
                      error: true,
                      errorMessage: error.response.data.errors,
                    });
                  } finally {
                    actions.setSubmitting(false);
                  }
                });
              }}
            >
              {({ values, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                  <RadioGroup
                    aria-label="Pessoa"
                    name="countryTaxNumberType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    row
                    value={values.countryTaxNumberType}
                  >
                    <FormControlLabel value="cnpj" control={<Radio />} label="Pessoa jurídica" />
                    <FormControlLabel value="cpf" control={<Radio />} label="Pessoa física" />
                  </RadioGroup>
                  <Field component={SimpleField} name="name" label="Nome" />
                  {values.countryTaxNumberType === 'cnpj' && (
                    <Field component={SimpleField} name="legalName" label="Razão Social" />
                  )}
                  <Grid container spacing={2}>
                    {values.countryTaxNumberType === 'cnpj' && (
                      <Grid item xs={12} sm={4}>
                        <Field component={CNPJField} name="cnpj" label="CNPJ" />
                      </Grid>
                    )}
                    {values.countryTaxNumberType === 'cpf' && (
                      <Grid item xs={12} sm={4}>
                        <Field component={CPFField} name="cpf" label="CPF" />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={4}>
                      <Field
                        component={SimpleField}
                        name="stateTaxNumber"
                        label="Inscrição Estadual"
                        required={false}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field component={RenasemField} name="renasem" label="RENASEM" />
                    </Grid>
                  </Grid>
                  {values.countryTaxNumberType === 'cnpj' && (
                    <React.Fragment>
                      <SectionHeader title="Representante Legal da Empresa" />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <Field component={SimpleField} name="legalRepresentative.firstName" label="Nome" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field component={SimpleField} name="legalRepresentative.lastName" label="Sobrenome" />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Field component={CPFField} name="legalRepresentative.countryTaxNumber" label="CPF" />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                  <SectionHeader title="Endereço e Telefone" />
                  <Field component={SimpleField} name="address.streetAddress" label="Endereço" />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Field component={PostalCodeField} name="address.postalCode" label="CEP" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field component={SimpleField} name="address.city" label="Municipio" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field component={SimpleField} name="address.state" label="Estado" />
                    </Grid>
                  </Grid>
                  <Field component={TelephoneField} name="phoneNumber" label="Telefone" />
                  <SectionHeader title="Responsável Técnico" />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field component={SimpleField} name="technician.firstName" label="Nome" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field component={SimpleField} name="technician.lastName" label="Sobrenome" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field component={TelephoneField} name="technician.phoneNumber" label="Telefone" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field component={EmailField} name="technician.email" label="E-mail" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field component={CPFField} name="technician.countryTaxNumber" label="CPF" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field component={RenasemField} name="technician.renasem" label="RENASEM" />
                    </Grid>
                  </Grid>
                  <SectionHeader title="Contato do Departamento Financeiro" />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field component={SimpleField} name="financeContact.firstName" label="Nome" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field component={SimpleField} name="financeContact.lastName" label="Sobrenome" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field component={TelephoneField} name="financeContact.phoneNumber" label="Telefone" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field component={EmailField} name="financeContact.email" label="E-mail" />
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    Enviar
                  </Button>
                </form>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProducerProfileForm);
