import axios from 'axios';

export default class LicensingProcessAttachment {
  constructor(data) {
    Object.assign(this, data);
  }

  static async get(id) {
    try {
      const response = await axios.get(`/licensingProcessAttachments/${id}`, {
        params: {
          projection: 'detail',
        },
      });
      response.data.id = id;
      return new LicensingProcessAttachment(response.data);
    } catch (error) {
      throw error;
    }
  }
}
