import React from 'react';
import { withStyles } from '@material-ui/core';

import Footer from './Footer';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    paddingBottom: theme.spacing(8),
    margin: 0,
  },
});

export default withStyles(styles)(({ children, classes, nav }) => (
  <React.Fragment>
    {nav}
    <main className={classes.layout}>{children}</main>
    <Footer />
  </React.Fragment>
));
