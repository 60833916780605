import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DownloadAttachmentLink from '../../components/DownloadAttachmentLink';
import FormattedDate from '../../components/FormattedDate';
import SeedCategory from '../../components/SeedCategory';

export default function RequestPhaseAwait({ process, t, user }) {
  if (user.isProducer && process.canRespondToRequest()) {
    const event = process.getRequestEvent();
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom>
            {user.email === event.userId ? 'Você' : <strong>{event.user}</strong>} enviou uma solicitação de
            licenciamento em{' '}
            <strong>
              <FormattedDate value={event.createdAt} />
            </strong>{' '}
            para plantar{' '}
            <strong>
              {process.area}
              &nbsp;ha
            </strong>{' '}
            da cultivar <strong>{process.cultivar.name}</strong> na categoria{' '}
            <strong>
              <SeedCategory value={process.seedCategory} />
            </strong>{' '}
            para a safra <strong>{process.seasonYear.id}</strong> anexando este{' '}
            <DownloadAttachmentLink id={event.attachment.id} process={process}>
              documento
            </DownloadAttachmentLink>{' '}
            para comprovar a origem das sementes.
          </Typography>
          <Typography>
            A solicitação será analisada pelo licenciador <strong>{process.licenser.name}</strong> em breve, por favor{' '}
            aguarde.
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
}
