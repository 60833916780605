import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseRounded';

const styles = (theme) => ({
  actions: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    margin: 0,
    padding: theme.spacing(2),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    scrollBehavior: 'smooth',
  },
  title: {
    margin: 0,
    padding: theme.spacing(2),
  },
});

export default withStyles(styles)(({ actions, classes, content, onClose, open, title }) => (
  <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
    scroll="paper"
    classes={{
      paperScrollPaper: classes.paper,
    }}
  >
    <DialogTitle className={classes.title} disableTypography>
      <Typography variant="h6">{title}</Typography>
      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent className={classes.content}>{content}</DialogContent>
    <DialogActions className={classes.actions}>{actions}</DialogActions>
  </Dialog>
));
