import React from 'react';
import CompanyProfile from './api/CompanyProfile';
import { DialogContent, Dialog, DialogTitle, Button, DialogContentText, DialogActions } from '@material-ui/core';
import Auth from './auth';
import { MixpanelContext } from './MixpanelContext';

export default function RegistrationNagware(props) {
  const mixpanel = React.useContext(MixpanelContext);
  const [companyProfile, setCompanyProfile] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    const fetchCompanyProfile = async () => {
      if (companyProfile === null) {
        const companyProfileFromCurrentUser = await CompanyProfile.getFromCurrentUser();
        if (!companyProfileFromCurrentUser.isComplete()) {
          setCompanyProfile(companyProfileFromCurrentUser);
          mixpanel.track('registration nagware');
          setOpen(true);
        }
      }
    };
    if (Auth.isAuthenticated() && Auth.user.isProducer) {
      fetchCompanyProfile();
    }
  });
  const onDisagree = () => setOpen(false);
  const onAgree = () => {
    setOpen(false);
    props.history.push('/produtores');
  };
  if (open) {
    return (
      <Dialog open={open} onClose={onDisagree} scroll="paper" aria-labelledby="scroll-dialog-title">
        <DialogTitle id="scroll-dialog-title">Atualização de Cadastro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            O seu cadastro de produtor não está completo, por favor atualize o cadastro e preencha todas as informações
            solicitadas.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDisagree}>Agora não</Button>
          <Button onClick={onAgree}>Ok</Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
}
