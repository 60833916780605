import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import * as YupValidation from '../shared/YupValidation';
import { Button, FormControlLabel, Grid, Radio, RadioGroup, Snackbar, withStyles } from '@material-ui/core';
import ProducerSignupAPI from '../api/ProducerSignup';
import { DialogLayout } from '../layouts';
import { MySnackbarContentWrapper } from '../components/CSnackbar';
import {
  TelephoneField,
  EmailField,
  RenasemField,
  PostalCodeField,
  SimpleField,
  CPFField,
  CNPJField,
} from '../components/inputs';
import SectionHeader from '../components/SectionHeader';

const validationSchema = Yup.object().shape({
  countryTaxNumberType: YupValidation.countryTaxNumberType,
  name: YupValidation.name,
  legalName: YupValidation.legalName,
  cnpj: YupValidation.cnpj,
  cpf: YupValidation.cpf,
  ie: YupValidation.stateTaxNumber,
  renasem: YupValidation.renasem,
  legalRepresentative: YupValidation.legalRepresentative,
  address: YupValidation.address,
  phoneNumber: YupValidation.phoneNumber,
  technician: YupValidation.technician,
  financeContact: YupValidation.financeContact,
  userFirstName: Yup.string().required('Informe o nome do usuário'),
  userLastName: Yup.string().required('Informe o sobrenome do usuário'),
  email: Yup.string().email('Informe um e-mail válido').required('Informe um e-mail válido'),
  password: Yup.string()
    .min(8, 'Informe uma senha com no mínimo 8 caracteres')
    .required('Informe uma senha com no mínimo 8 caracteres'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'Informe novamente a senha')
    .required('Informe novamente a senha'),
});

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class ProducerSignUp extends React.PureComponent {
  state = { error: undefined, errorMessage: undefined, success: undefined };
  handleCloseSuccessSnackbar = () => {
    this.props.history.push('/entrar');
  };
  handleCloseErrorSnackbar = () => {
    this.setState({ error: undefined });
  };
  render() {
    const { classes } = this.props;
    const { success, error, errorMessage } = this.state;
    return (
      <DialogLayout title="Cadastro de Produtor" subTitle="Preencha o formulário abaixo e clique Enviar">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={success === true}
          autoHideDuration={3000}
          onClose={this.handleCloseSuccessSnackbar}
        >
          <MySnackbarContentWrapper
            onClose={this.handleCloseSuccessSnackbar}
            variant="success"
            message="Cadastro realizado com sucesso! Aguarde..."
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error === true}
          onClose={this.handleCloseErrorSnackbar}
        >
          <MySnackbarContentWrapper onClose={this.handleCloseErrorSnackbar} variant="error" message={errorMessage} />
        </Snackbar>
        <Formik
          initialValues={{
            countryTaxNumberType: 'cnpj',
            name: '',
            legalName: '',
            cpf: '',
            cnpj: '',
            stateTaxNumber: '',
            renasem: '',
            address: {
              streetAddress: '',
              postalCode: '',
              city: '',
              state: '',
            },
            phoneNumber: '',
            legalRepresentative: {
              firstName: '',
              lastName: '',
              countryTaxNumber: null,
            },
            technician: {
              firstName: '',
              lastName: '',
              countryTaxNumber: null,
              phoneNumber: '',
              email: '',
              renasem: '',
            },
            financeContact: {
              firstName: '',
              lastName: '',
              phoneNumber: '',
              email: '',
            },
            userFirstName: '',
            userLastName: '',
            email: '',
            password: '',
            passwordConfirmation: '',
          }}
          onSubmit={async (values, actions) => {
            this.setState({ error: false });
            const result = await ProducerSignupAPI.save(values);
            if (result.success === true) {
              actions.setSubmitting(false);
              this.setState({ success: true });
            } else {
              actions.setSubmitting(false);
              this.setState({ error: true, errorMessage: result.message });
            }
          }}
          validateOnBlur
          validateOnChange={false}
          validationSchema={validationSchema}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <Grid container>
                <SectionHeader title="Produtor" subTitle="Informe os seus dados de identificação e de endereço" />
                <Grid item xs={12}>
                  <RadioGroup
                    aria-label="Pessoa"
                    name="countryTaxNumberType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    row
                    value={values.countryTaxNumberType}
                  >
                    <FormControlLabel value="cnpj" control={<Radio />} label="Pessoa jurídica" />
                    <FormControlLabel value="cpf" control={<Radio />} label="Pessoa física" />
                  </RadioGroup>

                  <Field component={SimpleField} label="Nome" name="name" />
                  {values.countryTaxNumberType === 'cnpj' && (
                    <Field component={SimpleField} label="Razão Social" name="legalName" />
                  )}
                </Grid>
                <Grid container item spacing={3}>
                  {values.countryTaxNumberType === 'cpf' && (
                    <Grid item xs>
                      <Field component={CPFField} label="CPF" name="cpf" />
                    </Grid>
                  )}
                  {values.countryTaxNumberType === 'cnpj' && (
                    <Grid item xs>
                      <Field component={CNPJField} label="CNPJ" name="cnpj" />
                    </Grid>
                  )}
                  <Grid item xs>
                    <Field component={SimpleField} label="Inscrição Estadual" name="stateTaxNumber" required={false} />
                  </Grid>
                  <Grid item xs>
                    <Field component={RenasemField} label="RENASEM" name="renasem" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field component={SimpleField} label="Endereço" name="address.streetAddress" />
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs={3}>
                    <Field component={PostalCodeField} label="CEP" name="address.postalCode" />
                  </Grid>
                  <Grid item xs={7}>
                    <Field component={SimpleField} label="Municipio" name="address.city" />
                  </Grid>
                  <Grid item xs={2}>
                    <Field component={SimpleField} label="Estado" name="address.state" />
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Field component={TelephoneField} label="Telefone" name="phoneNumber" />
                </Grid>
                {values.countryTaxNumberType === 'cnpj' && (
                  <React.Fragment>
                    <SectionHeader title="Representante Legal" subTitle="Informe os dados do seu representante legal" />
                    <Grid container item spacing={3}>
                      <Grid item xs>
                        <Field component={SimpleField} label="Nome" name="legalRepresentative.firstName" />
                      </Grid>
                      <Grid item xs>
                        <Field component={SimpleField} label="Sobrenome" name="legalRepresentative.lastName" />
                      </Grid>
                      <Grid item xs>
                        <Field component={CPFField} label="CPF" name="legalRepresentative.countryTaxNumber" />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                <SectionHeader
                  title="Responsável Técnico"
                  subTitle="Informe os dados de contato do responsável técnico"
                />
                <Grid container item spacing={3}>
                  <Grid item xs>
                    <Field component={SimpleField} label="Nome" name="technician.firstName" />
                  </Grid>
                  <Grid item xs>
                    <Field component={SimpleField} label="Sobrenome" name="technician.lastName" />
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs>
                    <Field component={TelephoneField} label="Telefone" name="technician.phoneNumber" />
                  </Grid>
                  <Grid item xs>
                    <Field component={EmailField} label="E-mail" name="technician.email" />
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs>
                    <Field component={CPFField} label="CPF" name="technician.countryTaxNumber" />
                  </Grid>
                  <Grid item xs>
                    <Field component={RenasemField} label="RENASEM" name="technician.renasem" />
                  </Grid>
                </Grid>
                <SectionHeader
                  title="Contato Financeiro"
                  subTitle="Informe os dados de contato da pessoa responsável pelo departamento financeiro"
                />
                <Grid container item spacing={3}>
                  <Grid item xs>
                    <Field component={SimpleField} label="Nome" name="financeContact.firstName" />
                  </Grid>
                  <Grid item xs>
                    <Field component={SimpleField} label="Sobrenome" name="financeContact.lastName" />
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs>
                    <Field component={TelephoneField} label="Telefone" name="financeContact.phoneNumber" />
                  </Grid>
                  <Grid item xs>
                    <Field component={EmailField} label="E-mail" name="financeContact.email" />
                  </Grid>
                </Grid>
                <SectionHeader title="Usuário" subTitle="Informe o usuário principal para acesso ao NetSeeds" />
                <Grid container item spacing={3}>
                  <Grid item xs>
                    <Field component={SimpleField} label="Nome" name="userFirstName" />
                  </Grid>
                  <Grid item xs>
                    <Field component={SimpleField} label="Sobrenome" name="userLastName" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Field component={EmailField} label="E-mail" name="email" />
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item xs>
                    <Field component={SimpleField} label="Senha" name="password" type="password" />
                  </Grid>
                  <Grid item xs>
                    <Field
                      component={SimpleField}
                      label="Confirmação da senha"
                      name="passwordConfirmation"
                      type="password"
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting || success === true}
                >
                  Enviar
                </Button>
              </Grid>
            </form>
          )}
        </Formik>
      </DialogLayout>
    );
  }
}

export default withStyles(styles)(ProducerSignUp);
