import React from 'react';
import Licenciador from './Licenciador';
import Produtor from './Produtor';

const Produtores = ({ user, ...props }) => {
  if (user.isLicenser || user.isBreeder || user.isBreederViewer) {
    return <Licenciador {...props} user={user} />;
  }
  if (user.isProducer) {
    return <Produtor {...props} user={user} />;
  }
  return null;
};

export default Produtores;
