import { conformToMask } from 'text-mask-core';

const mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

const format = (raw) => conformToMask(raw, mask).conformedValue;

const CNPJ = {
  mask,
  format,
};

export default CNPJ;
