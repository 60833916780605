import * as Yup from 'yup';
import * as BRValidations from 'br-validations';

export const legalRepresentative = Yup.object().when('countryTaxNumberType', {
  is: 'cnpj',
  then: Yup.object({
    firstName: Yup.string().nullable().required('Informe o nome do representante legal do produtor'),
    lastName: Yup.string().nullable().required('Informe o sobrenome do representante legal do produtor'),
    countryTaxNumber: Yup.string()
      .nullable()
      .required('Informe o CPF do representante legal do produtor')
      .test('valid-cpf', 'Informe um CPF válido', (value) => value && BRValidations.cpf.validate(value)),
  }),
});

export const countryTaxNumberType = Yup.string();

export const phoneNumber = Yup.string().nullable().required('Informe o número de telefone do produtor');

export const renasem = Yup.string().nullable().required('Informe o RENASEM do produtor');

export const stateTaxNumber = Yup.string().nullable().when('countryTaxNumberType', {
  is: 'cnpj',
  then: Yup.string(),
});

export const name = Yup.string().nullable().required('Informe o nome do produtor');

export const legalName = Yup.string().when('countryTaxNumberType', {
  is: 'cnpj',
  then: Yup.string().nullable().required('Informe a razão social do produtor'),
});

export const cnpj = Yup.string()
  .nullable()
  .when('countryTaxNumberType', {
    is: 'cnpj',
    then: Yup.string()
      .nullable()
      .required('Informe o CNPJ do produtor')
      .test('valid-cnpj', 'Informe um CNPJ válido', (value) => value && BRValidations.cnpj.validate(value)),
  });

export const cpf = Yup.string()
  .nullable()
  .when('countryTaxNumberType', {
    is: 'cpf',
    then: Yup.string()
      .nullable()
      .required('Informe o CPF do produtor')
      .test('valid-cpf', 'Informe um CPF válido', (value) => value && BRValidations.cpf.validate(value)),
  });

export const technician = Yup.object().shape({
  firstName: Yup.string().nullable().required('Informe o nome do responsável técnico'),
  lastName: Yup.string().nullable().required('Informe o sobrenome do responsável técnico'),
  phoneNumber: Yup.string().nullable().required('Informe o número de telefone do responsável técnico'),
  email: Yup.string()
    .nullable()
    .email('Informe um e-mail válido para o responsável técnico')
    .required('Informe o e-mail do responsável técnico'),
  renasem: Yup.string().nullable().required('Informe o RENASEM do responsável técnico'),
  countryTaxNumber: Yup.string()
    .nullable()
    .required('Informe o CPF do responsável técnico')
    .test('valid-cpf', 'Informe um CPF válido', (value) => value && BRValidations.cpf.validate(value)),
});

export const financeContact = Yup.object().shape({
  firstName: Yup.string().nullable().required('Informe o nome do contato do departamento financeiro'),
  lastName: Yup.string().nullable().required('Informe o sobrenome do contato do departamento financeiro'),
  phoneNumber: Yup.string().nullable().required('Informe o número de telefone do contato do departamento financeiro'),
  email: Yup.string()
    .nullable()
    .email('Informe um e-mail válido para o contato do departamento financeiro')
    .required('Informe o e-mail do contato do departamento financeiro'),
});

export const address = Yup.object().shape({
  streetAddress: Yup.string().nullable().required('Informe o endereço do produtor'),
  postalCode: Yup.string().nullable().required('Informe o CEP do endereço do produtor'),
  city: Yup.string().nullable().required('Informe a cidade do endereço do produtor'),
  state: Yup.string().nullable().required('Informe a sigla do estado do endereço do produtor'),
});
