import React from 'react';
import { TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import Renasem from '../../shared/Renasem';
import CPF from '../../shared/CPF';
import CNPJ from '../../shared/CNPJ';
import Email from '../../shared/Email';
import PostalCode from '../../shared/PostalCode';
import Telephone from '../../shared/Telephone';

const withMask = (mask) => (props) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
    />
  );
};

export function SimpleField({
  field: { name, ...field },
  form: { touched, errors, ...form },
  required = true,
  type = 'text',
  inputComponent = undefined,
  ...props
}) {
  const meta = form.getFieldMeta(name);
  return (
    <TextField
      {...field}
      {...props}
      name={name}
      type={type}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputComponent,
      }}
      margin="normal"
      required={required}
      fullWidth
      variant="outlined"
      helperText={meta.touched && meta.error}
      error={meta.touched && !!meta.error}
    />
  );
}

const CNPJInputComponent = withMask(CNPJ.mask);
export const CNPJField = (props) => <SimpleField inputComponent={CNPJInputComponent} {...props} />;

const CPFInputComponent = withMask(CPF.mask);
export const CPFField = (props) => <SimpleField inputComponent={CPFInputComponent} {...props} />;

const RenasemInputComponent = withMask(Renasem.mask);
export const RenasemField = (props) => <SimpleField inputComponent={RenasemInputComponent} {...props} />;

const PostalCodeInputComponent = withMask(PostalCode.mask);
export const PostalCodeField = (props) => <SimpleField inputComponent={PostalCodeInputComponent} {...props} />;

const TelephoneInputComponent = withMask((raw) => Telephone.mask(raw));
export const TelephoneField = (props) => <SimpleField inputComponent={TelephoneInputComponent} {...props} />;

const EmailInputComponent = withMask(Email.mask);
export const EmailField = (props) => <SimpleField inputComponent={EmailInputComponent} {...props} />;
