import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { Button, Snackbar, TextField, Typography, withStyles } from '@material-ui/core';
import Auth from '../auth';
import { DialogLayout } from '../layouts';
import { MySnackbarContentWrapper as SnackbarContent } from '../components/CSnackbar';
import { MixpanelContext } from '../MixpanelContext';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Informe um e-mail válido!').required('Informe o seu e-mail!'),
  password: Yup.string().required('Informe a sua senha!'),
});

const styles = (theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  error: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
});

class Entrar extends React.Component {
  static contextType = MixpanelContext;
  state = { error: undefined, redirectToReferrer: false, success: undefined };

  handleCloseSuccessSnackbar = () => {
    this.props.history.push('/');
  };

  handleCloseErrorSnackbar = () => {
    this.setState({ error: undefined });
  };

  render() {
    const { classes, user } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/' } };
    const { error, redirectToReferrer } = this.state;
    const mixpanel = this.context;
    if (redirectToReferrer) return <Redirect to={from} />;
    return (
      <DialogLayout title="Entrar">
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={error === true}
          onClose={this.handleCloseErrorSnackbar}
        >
          <SnackbarContent onClose={this.handleCloseErrorSnackbar} variant="error" message="Informações inválidas!" />
        </Snackbar>
        <Formik
          initialValues={user /** { email, social } */}
          validationSchema={validationSchema}
          onSubmit={async (values, actions) => {
            const login = await Auth.login(values.email, values.password);
            if (login.success === true) {
              actions.setSubmitting(false);
              mixpanel.identify(values.email);
              mixpanel.people.set({
                $email: values.email,
                $last_login: new Date(),
              });
              mixpanel.track('login', { result: 'success' });
              this.setState({ redirectToReferrer: true });
            } else {
              mixpanel.track('login', { result: 'failure' });
              this.setState({ error: true });
              actions.setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
            <form className={classes.form} onSubmit={handleSubmit} noValidate>
              <TextField
                id="email"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                label="E-mail"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.email && errors.email}
                error={!!errors.email && !!touched.email}
              />
              <TextField
                id="password"
                name="password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                label="Senha"
                margin="normal"
                required
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                helperText={touched.password && errors.password}
                error={!!errors.password && !!touched.password}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Enviar
              </Button>
              <Typography align="center" component="p">
                <Link to="/recuperar-senha">Esqueci a senha</Link> | <Link to="/cadastro">Não tenho cadastro</Link>
              </Typography>
            </form>
          )}
        </Formik>
      </DialogLayout>
    );
  }
}

Entrar.propTypes = {
  onLogIn: PropTypes.func,
  user: PropTypes.shape({}),
};

Entrar.defaultProps = {
  onLogIn: () => {},
  user: {
    email: '',
    password: '',
  },
};

export default withStyles(styles)(Entrar);
