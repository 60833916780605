import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormattedDate from '../../components/FormattedDate';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { MySnackbarContentWrapper as SnackbarContent } from '../../components/CSnackbar';

const validationSchema = Yup.object().shape({
  message: Yup.string()
    .required('Informe uma mensagem')
    .max(1000, 'Informe uma mensagem com no máximo 1000 caracteres'),
});

export const MessagesActions = ({ onEvent, process }) => (
  <Formik
    initialValues={{ message: '' }}
    enableReinitialize
    validationSchema={validationSchema}
    onSubmit={async (values, actions) => {
      await onEvent(async () => {
        try {
          await process.postMessage({
            message: values.message,
          });
          values.message = '';
          actions.setStatus({ success: 'Mensagem enviada!' });
        } catch (error) {
          console.log(error);
          actions.setStatus({
            error: 'Não foi possível enviar a mensagem!',
          });
        }
      });
      actions.setSubmitting(false);
    }}
  >
    {({ values, errors, status, setStatus, touched, handleBlur, handleChange, handleSubmit, isSubmitting }) => (
      <form onSubmit={handleSubmit} noValidate style={{ width: '100%' }}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={status && status.error !== undefined}
          onClose={() => setStatus({ error: undefined })}
        >
          <SnackbarContent
            onClose={() => setStatus({ error: undefined })}
            variant="error"
            message={status && status.error}
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={status && status.success !== undefined}
          onClose={() => setStatus({ success: undefined })}
        >
          <SnackbarContent
            onClose={() => setStatus({ success: undefined })}
            variant="success"
            message={status && status.success}
          />
        </Snackbar>
        <TextField
          id="message"
          name="message"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.message}
          label="Mensagem"
          multiline
          rows="2"
          margin="normal"
          InputProps={{
            inputProps: {
              maxLength: 1000,
            },
          }}
          required
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          helperText={touched.message && errors.message}
          error={!!errors.message && !!touched.message}
        />
        <Button
          color="primary"
          disabled={isSubmitting || process.deleted}
          fullWidth
          onClick={handleSubmit}
          variant="contained"
        >
          Enviar
        </Button>
      </form>
    )}
  </Formik>
);

const styles = {
  message: {
    marginBottom: '1.35em',
    whiteSpace: 'pre-wrap',
  },
};

const Message = withStyles(styles)(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.ref = React.createRef();
    }

    componentDidMount() {
      this.scrollIntoView();
    }

    componentDidUpdate() {
      this.scrollIntoView();
    }

    scrollIntoView = () => {
      const { last } = this.props;
      if (last) {
        this.ref.current.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: 'nearest',
        });
      }
    };

    render() {
      const { classes, message } = this.props;
      return (
        <div ref={this.ref}>
          <Typography variant="caption" color="textSecondary">
            <FormattedDate value={message.createdAt} />
          </Typography>
          <Typography variant="subtitle2">{message.user}</Typography>
          <Typography component="pre" className={classes.message}>
            {message.data.message}
          </Typography>
        </div>
      );
    }
  }
);

export const MessagesContent = ({ process }) => (
  <React.Fragment>
    {process.messages.map((message, i, arr) => (
      <Message key={message.createdAt} message={message} last={i === arr.length - 1} />
    ))}
    {process.messages.length === 0 && <Typography>Por enquanto não existem mensagens.</Typography>}
  </React.Fragment>
);
