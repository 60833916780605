import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CultivaresList from './CultivaresList';
import CultivarDetail from './CultivarDetail';

export default function Cultivares({ nav }) {
  return (
    <Switch>
      <Route path="/cultivares" exact render={(props) => <CultivaresList {...props} nav={nav} />} />
      <Route path="/cultivares/:id" render={(props) => <CultivarDetail {...props} nav={nav} />} />
    </Switch>
  );
}
