import { conformToMask } from 'text-mask-core';

const mask = (raw) => {
  const numbers = raw.match(/\d/g) || [];
  if (numbers.join('').length > 10) {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  } else {
    return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
};

const format = (raw) => conformToMask(raw, mask).conformedValue;

const Telephone = {
  mask,
  format,
};

export default Telephone;
