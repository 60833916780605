import React from 'react';
import { Link, Redirect, Switch } from 'react-router-dom';
import { Button } from '@material-ui/core';
import PrivateRoute from '../../../PrivateRoute';
import CompanyProfileAPI from '../../../api/CompanyProfile';
import { MainLayout } from '../../../layouts';
import { LoadingContext } from '../../../LoadingContext';
import DialogForm from './DialogForm';

export default class Produtor extends React.Component {
  static contextType = LoadingContext;

  state = {
    companyProfile: { id: '', textId: '', name: '' },
    ready: false,
  };

  async componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { startLoading, stopLoading } = this.context;
    startLoading();
    const id = this.props.match.params.id;
    const companyProfile =
      id === undefined ? await CompanyProfileAPI.getFromCurrentUser() : await CompanyProfileAPI.get(id);
    stopLoading();
    const ready = true;
    this.setState({ companyProfile, ready });
  };

  wrapCallbackLoading = async (cb) => {
    const { startLoading, stopLoading } = this.context;
    startLoading();
    await cb();
    await this.fetchData();
    stopLoading();
  };

  render() {
    const { nav, user } = this.props;
    const { companyProfile, ready } = this.state;
    return (
      <MainLayout
        nav={nav}
        title={companyProfile.name}
        actions={
          <Button component={Link} variant="outlined" to={`/produtores/${companyProfile.textId}/editar`}>
            Editar
          </Button>
        }
        ready={ready}
        breadcrumbs={[
          { path: '/', label: 'Início' },
          { path: '/produtores', label: 'Produtores' },
          {
            path: `/produtores/${companyProfile.textId}`,
            label: companyProfile.name,
          },
        ]}
      >
        <Switch>
          <PrivateRoute
            path="/produtores"
            exact
            render={() => <Redirect to={`/produtores/${companyProfile.textId}/editar`} />}
          />
          <PrivateRoute
            path="/produtores/:id/editar"
            exact
            render={(props) => (
              <React.Fragment>
                {user.isProducer && (
                  <DialogForm {...props} onEvent={this.wrapCallbackLoading} companyProfile={companyProfile} />
                )}
              </React.Fragment>
            )}
          />
        </Switch>
      </MainLayout>
    );
  }
}
