import axios from 'axios';

const save = async (data) => {
  try {
    await axios.post('/producerSignups', data);
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response.data.errors,
    };
  }
};

const ProducerSignup = { save };

export default ProducerSignup;
