import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function ContractPhaseIssueAwait({ process, user }) {
  if (user.isProducer && process.canIssueContract()) {
    return (
      <Typography>
        O contrato será emitido pelo licenciador <strong>{process.licenser.name}</strong> em breve, por favor aguarde.
      </Typography>
    );
  }
  return null;
}
