import { conformToMask } from 'text-mask-core';

const mask = [/[a-zA-Z]/, /[a-zA-Z]/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

const format = (raw) => {
  return raw && conformToMask(raw, mask).conformedValue;
};

const Renasem = {
  mask,
  format,
};

export default Renasem;
