import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNewRounded';
import FormattedNumber from '../../components/FormattedNumber';
import SeedCategory from '../../components/SeedCategory';
import Renasem from '../../shared/Renasem';

export default function DetailsContent({ process, user }) {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Cultura
        </Typography>
        <Typography variant="subtitle2">{process.cultivar.crop.name}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Cultivar
        </Typography>
        <Typography variant="subtitle2">{process.cultivar.name}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Área
        </Typography>
        <Typography variant="subtitle2">
          <FormattedNumber value={process.area} suffix=" ha" />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Categoria
        </Typography>
        <Typography variant="subtitle2">
          <SeedCategory value={process.seedCategory} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          Safra
        </Typography>
        <Typography variant="subtitle2">{process.seasonYear.id}</Typography>
      </Grid>
      {user.isProducer && (
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Licenciador
          </Typography>
          <Typography variant="subtitle2">{process.licenser.name}</Typography>
        </Grid>
      )}
      {!user.isProducer && (
        <React.Fragment>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              Produtor
            </Typography>
            <Link component={RouterLink} to={`/produtores/${process.producer.textId}`} variant="subtitle2">
              {process.producer.name}
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textSecondary">
              RENASEM do Produtor
            </Typography>
            <Typography variant="subtitle2">
              <Link
                href={`http://sistemasweb.agricultura.gov.br/renasem/psq_consultarenasems.do?valor(cdRenasemPesquisa)=${Renasem.format(
                  process.producer.renasem
                )}`}
                target="_blank"
              >
                {Renasem.format(process.producer.renasem)}
                &nbsp;
                <OpenInNewIcon fontSize="inherit" />
              </Link>
            </Typography>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}
