import React from 'react';
import { Grid, Table, Typography, TableBody, TableRow, TableCell } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import InfoIcon from '@material-ui/icons/Info';

export default function Info({ cultivar }) {
  return (
    <Grid container item direction="column" spacing={24}>
      <Grid item>
        <Typography component="h3" variant="h5" gutterBottom>
          <CheckIcon style={{ position: 'relative', top: '.125em' }} /> Informações gerais
        </Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell style={{ width: '50%' }}>
                <strong>Cultura</strong>
              </TableCell>
              <TableCell>{cultivar.crop.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Obtentor</strong>
              </TableCell>
              <TableCell>{cultivar.breeder.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Licenciador</strong>
              </TableCell>
              <TableCell>{cultivar.licenser.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Exclusividade</strong>
              </TableCell>
              <TableCell>{['Agro Redentora', 'AF 1345'].includes(cultivar.name) ? 'Sim' : 'Não'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
      {cultivar.datas.length > 0 && (
        <Grid item>
          <Typography component="h3" variant="h5" gutterBottom>
            <InfoIcon style={{ position: 'relative', top: '.125em' }} /> Características Técnicas
          </Typography>
          <Table>
            <TableBody>
              {cultivar.datas.map((data) => (
                <TableRow>
                  <TableCell style={{ width: '50%' }}>
                    <strong>{data.itemKey}</strong>
                  </TableCell>
                  <TableCell>{data.itemValue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  );
}
