import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

export default function ContractPhaseResult({ process, user }) {
  if (process.isBeyondContractPhase()) {
    const issueEvent = process.getIssueContractEvent();
    const responseEvent = process.getRespondToContractEvent();
    return (
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography gutterBottom>
            {user.email === issueEvent.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{issueEvent.user}</strong> da <strong>{issueEvent.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={issueEvent} process={process} /> em{' '}
            <strong>
              <FormattedDate value={issueEvent.createdAt} />
            </strong>
            .
          </Typography>
          <Typography>
            {user.email === responseEvent.userId ? (
              'Você'
            ) : (
              <React.Fragment>
                <strong>{responseEvent.user}</strong> da <strong>{responseEvent.company}</strong>
              </React.Fragment>
            )}{' '}
            <EventMessage active event={responseEvent} process={process} /> em{' '}
            <strong>
              <FormattedDate value={responseEvent.createdAt} />
            </strong>
            .
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return null;
}
