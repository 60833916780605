import React from 'react';
import { Button, Grid, Menu, MenuItem, withStyles } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/AttachFileRounded';
import EmailIcon from '@material-ui/icons/EmailRounded';
import HistoryIcon from '@material-ui/icons/HistoryRounded';
import MoreVertIcon from '@material-ui/icons/MoreVertRounded';
import MainLayout from '../../layouts/Main';
import { LoadingContext } from '../../LoadingContext';
import LicensingProcess from '../../api/LicensingProcess';
import { CancelProcessActions, CancelProcessContent } from './CancelProcess';
import ContractPhaseIssueAwait from './ContractPhaseIssueAwait';
import ContractPhaseRespondAwait from './ContractPhaseRespondAwait';
import ContractPhaseIssue from './ContractPhaseIssue';
import ContractPhaseRespond from './ContractPhaseRespond';
import ContractPhaseResult from './ContractPhaseResult';
import FieldsPhaseIssueAwait from './FieldsPhaseIssueAwait';
import FieldsPhaseRespondAwait from './FieldsPhaseRespondAwait';
import FieldsPhaseIssue from './FieldsPhaseIssue';
import FieldsPhaseRespond from './FieldsPhaseRespond';
import DetailsContent from './DetailsContent';
import { MessagesContent, MessagesActions } from './Messages';
import { AttachmentsContent } from './Attachments';
import { HistoryContent } from './History';
import CardWithTitle from './CardWithTitle';
import DialogWithTitle from './DialogWithTitle';
import RequestPhaseAwait from './RequestPhaseAwait';
import RequestPhaseRespond from './RequestPhaseRespond';
import RequestPhaseResult from './RequestPhaseResult';
import RoyaltiesContent from './RoyaltiesContent';
import YieldPhaseRespond from './YieldPhaseRespond';
import YieldPhaseAwait from './YieldPhaseAwait';
import FieldSchedule from './FieldSchedule';
import { Redirect } from 'react-router-dom';

const styles = (theme) => ({
  button: {
    marginLeft: theme.spacing(1),
  },
});

class LicensingProcessDetail extends React.Component {
  static contextType = LoadingContext;

  state = {
    messagesDialogOpen: false,
    historyDialogOpen: false,
    attachmentsDialogOpen: false,
    cancelProcessDialogOpen: false,
    process: new LicensingProcess(),
    ready: false,
  };

  async componentDidMount() {
    const { startLoading, stopLoading } = this.context;
    try {
      const id = this.props.match.params.id;
      startLoading();
      const process = await LicensingProcess.get(id);
      stopLoading();
      const ready = true;
      this.setState({ process, ready });
    } catch (error) {
      this.setState({ error, process: null, ready: true });
      stopLoading();
    }
  }

  submitEvent = async (eventCallback) => {
    const id = this.props.match.params.id;
    const { startLoading, stopLoading } = this.context;
    startLoading();
    await eventCallback();
    const process = await LicensingProcess.get(id);
    stopLoading();
    this.setState({ process });
  };

  openDialog = (dialog) => this.setState({ [`${dialog}DialogOpen`]: true });

  render() {
    const { user } = this.context;
    const { classes, nav } = this.props;
    const id = this.props.match.params.id;
    const {
      messagesDialogOpen,
      historyDialogOpen,
      attachmentsDialogOpen,
      cancelProcessDialogOpen,
      anchorEl,
      process,
      ready,
      error,
    } = this.state;

    const child = {
      process: process,
      user,
      onEvent: this.submitEvent,
    };

    if (error) {
      return <Redirect to="/licenciamento" />;
    }

    return (
      <MainLayout
        breadcrumbs={[
          { path: '/', label: 'Início' },
          { path: '/licenciamento', label: 'Licenciamento' },
          {
            path: `/licenciamento/${id}`,
            label: `Processo #${id}`,
          },
        ]}
        nav={nav}
        ready={ready}
        title={`Processo #${id}${process.deleted ? ' (cancelado)' : ''}`}
        actions={
          <React.Fragment>
            <Button className={classes.button} variant="text" onClick={() => this.openDialog('attachments')}>
              <AttachmentIcon />
            </Button>
            {!user.isBreederViewer && (
              <Button className={classes.button} variant="text" onClick={() => this.openDialog('history')}>
                <HistoryIcon />
              </Button>
            )}
            <Button className={classes.button} variant="text" onClick={() => this.openDialog('messages')}>
              <EmailIcon />
            </Button>
            {(user.isLicenser || user.isBreeder) && (
              <React.Fragment>
                <Button
                  className={classes.button}
                  variant="text"
                  onClick={(event) => {
                    this.setState({ anchorEl: event.currentTarget });
                  }}
                >
                  <MoreVertIcon />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => {
                    this.setState({ anchorEl: null });
                  }}
                >
                  <MenuItem disabled={process.deleted} onClick={() => this.openDialog('cancelProcess')}>
                    Cancelar processo
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </React.Fragment>
        }
      >
        <DialogWithTitle
          onClose={() => this.setState({ messagesDialogOpen: false })}
          open={messagesDialogOpen}
          title="Mensagens"
          content={<MessagesContent {...child} />}
          actions={<MessagesActions {...child} />}
        />
        <DialogWithTitle
          onClose={() => this.setState({ attachmentsDialogOpen: false })}
          open={attachmentsDialogOpen}
          title="Documentos"
          content={<AttachmentsContent {...child} />}
        />
        <DialogWithTitle
          onClose={() => this.setState({ historyDialogOpen: false })}
          open={historyDialogOpen}
          title="Histórico"
          content={<HistoryContent {...child} />}
        />
        <DialogWithTitle
          onClose={() => this.setState({ cancelProcessDialogOpen: false })}
          open={cancelProcessDialogOpen}
          title="Cancelar processo"
          content={<CancelProcessContent {...child} />}
          actions={
            <CancelProcessActions
              {...child}
              onEvent={async (cb) => {
                await this.submitEvent(cb);
                this.setState({ cancelProcessDialogOpen: false });
              }}
            />
          }
        />
        <Grid container item spacing={2}>
          <Grid container item xs={12} sm={3} direction="column">
            <CardWithTitle title="Detalhes">
              <DetailsContent {...child} />
            </CardWithTitle>
          </Grid>
          <Grid container item xs={12} sm={6} direction="column">
            {process.isInYieldPhaseOrBeyond() && (
              <CardWithTitle title="Produção">
                <YieldPhaseRespond {...child} />
                <YieldPhaseAwait {...child} />
              </CardWithTitle>
            )}
            {process.isInFieldsPhaseOrBeyond() && (
              <CardWithTitle title="Campos">
                <FieldsPhaseIssue {...child} />
                <FieldsPhaseIssueAwait {...child} />
                <FieldsPhaseRespond {...child} />
                <FieldsPhaseRespondAwait {...child} />
              </CardWithTitle>
            )}
            {process.isInContractPhaseOrBeyond() && (
              <CardWithTitle title="Contrato">
                <ContractPhaseIssue {...child} />
                <ContractPhaseIssueAwait {...child} />
                <ContractPhaseRespond {...child} />
                <ContractPhaseRespondAwait {...child} />
                <ContractPhaseResult {...child} />
              </CardWithTitle>
            )}
            {process.isInRequestPhaseOrBeyond() && (
              <CardWithTitle title="Solicitação">
                <RequestPhaseRespond {...child} />
                <RequestPhaseAwait {...child} />
                <RequestPhaseResult {...child} />
              </CardWithTitle>
            )}
          </Grid>
          <Grid container item xs={12} sm={3} direction="column">
            {process.isInYieldPhaseOrBeyond() && process.fieldSchedule?.length > 0 && (
              <CardWithTitle title="Cronograma">
                <FieldSchedule {...child} />
              </CardWithTitle>
            )}
            {!user.isBreederViewer && (
              <CardWithTitle title={<em>Royalties</em>}>
                <RoyaltiesContent {...child} />
              </CardWithTitle>
            )}
          </Grid>
        </Grid>
      </MainLayout>
    );
  }
}

export default withStyles(styles)(LicensingProcessDetail);
