import React from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Card, CardActionArea, CardContent, Chip, Grid, Typography, withStyles } from '@material-ui/core';
import CultivarAPI from '../api/Cultivar';
import { LoadingContext } from '../LoadingContext';
import { MainLayout } from '../layouts';

class CultivaresList extends React.Component {
  static contextType = LoadingContext;
  state = { cultivars: undefined, ready: false };

  async componentDidMount() {
    const { startLoading, stopLoading } = this.context;
    startLoading();
    const cultivars = await CultivarAPI.list(this.isTesting());
    stopLoading();
    const ready = true;
    this.setState({ cultivars, ready });
  }

  isTesting = () => queryString.parse(this.props.location.search).testing === 'true';

  render() {
    let meat = null;
    const { cultivars, ready } = this.state;
    if (cultivars && cultivars.length > 0) {
      meat = (
        <Grid container item xs={12} spacing={2}>
          {cultivars.map((cultivar) => (
            <Grid item key={cultivar.id} xs={12} sm={3}>
              <Card>
                <CardActionArea component={Link} to={`/cultivares/${cultivar.id}`}>
                  <CardContent>
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        <Typography component="h4" variant="h6" gutterBottom>
                          {cultivar.name}{' '}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {['Agro Iraí', 'AF 1345'].includes(cultivar.name) && (
                          <Chip component="span" label="Exclusiva" color="primary" variant="outlined" />
                        )}
                      </Grid>
                    </Grid>
                    <Typography component="h5" variant="body2" gutterBottom>
                      <em>{cultivar.crop.name}</em> da <strong>{cultivar.breeder.name}</strong>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      );
    }
    return (
      <MainLayout
        {...this.props}
        title="Cultivares"
        subtitle="A tabela abaixo lista todas as cultivares disponíveis para licenciamento. Clique sobre o nome da cultivar para ter mais informações sobre ela e também iniciar o processo de licenciamento."
        breadcrumbs={[
          { path: '/', label: 'Início' },
          { path: '/cultivares', label: 'Cultivares' },
        ]}
        ready={ready}
      >
        {meat}
      </MainLayout>
    );
  }
}

const styles = (theme) => ({});

export default withStyles(styles)(CultivaresList);
