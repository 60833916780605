import axios from 'axios';
import dayjs from 'dayjs';
import LicensingProcessEvent from './LicensingProcessEvent';

const baseQueryParams = {
  projection: 'summary',
  size: 12,
  sort: 'updatedAt,desc',
};

const ORD = {
  homologatedArea: 1,
  approvedArea: 2,
  receivedSeedAmount: 3,
  processedSeedAmount: 4,
  approvedSeedAmount: 5,
  ownUseSeedAmount: 6,
  commercializedSeedAmount: 7,
  commercializedSeedValue: 8,
};

const byLimitDateAndFieldOrder = (a, b) => {
  if (a.limitDate < b.limitDate) {
    return -1;
  }
  if (a.limitDate > b.limitDate) {
    return 1;
  }
  if (ORD[a.fieldNameCamelCase] < ORD[b.fieldNameCamelCase]) {
    return -1;
  }
  if (ORD[a.fieldNameCamelCase] > ORD[b.fieldNameCamelCase]) {
    return 1;
  }
  return 0;
};

export default class LicensingProcess {
  constructor(data) {
    if (data) {
      Object.assign(this, data);
      this.messages = this.events.filter((event) => event.type === 'MESSAGE_POSTED');
    } else {
      Object.assign(this, {
        id: '',
        events: [],
        licenser: { name: '' },
        producer: { name: '' },
        cultivar: { crop: { name: '' } },
        seasonYear: { id: '' },
        category: '',
        messages: [],
        fieldSchedule: [],
      });
    }
    this.fieldReadOnlyMap = {};

    const fieldsSorted = this.fieldSchedule.sort(byLimitDateAndFieldOrder);
    this.fieldsGroupedByLimitDate = [];
    let lastLimitDate = null;
    let now = dayjs();
    let cccc = null;
    fieldsSorted.forEach((field) => {
      if (field.limitDate === lastLimitDate) {
        this.fieldsGroupedByLimitDate[this.fieldsGroupedByLimitDate.length - 1].fields.push(field);
      } else {
        this.fieldsGroupedByLimitDate.push({
          date: field.limitDate,
          fields: [field],
        });
        lastLimitDate = field.limitDate;
      }
      if ((cccc === null || now.isAfter(cccc, 'day')) && now.isBefore(dayjs(field.limitDate), 'day')) {
        cccc = field.limitDate;
      }
    });
    fieldsSorted.forEach((field) => {
      this.fieldReadOnlyMap[field.fieldNameCamelCase] = dayjs(field.limitDate).isAfter(dayjs(cccc), 'day');
    });
  }

  static async query(params = {}) {
    try {
      const response = await axios.get('/licensingProcesses', {
        params: {
          ...baseQueryParams,
          ...params,
        },
      });
      return {
        items: response.data._embedded.licensingProcesses,
        page: response.data.page,
      };
    } catch (error) {
      console.log(error, error.response);
    }
  }

  static async get(id) {
    try {
      const response = await axios.get(`/licensingProcesses/${id}`, {
        params: {
          projection: 'detail',
        },
      });
      response.data.id = id;
      return new LicensingProcess(response.data);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  getLatestEvent() {
    if (!this.events) {
      return null;
    }
    return this.events[this.events.length - 1];
  }

  isTransitionAvailableToRole(roles) {
    if (!roles) {
      return false;
    }
    if (this.availableTransitions) {
      return this.availableTransitions.some(function (v) {
        return roles.indexOf(v.requiredRole) !== -1;
      });
    }
  }

  transitionsContain(transitions) {
    if (!transitions) {
      return false;
    }
    if (!this.availableTransitions) {
      return false;
    }
    return this.availableTransitions.some(function (v) {
      return transitions.indexOf(v.type) !== -1;
    });
  }

  isInAvailableTransitions(...types) {
    return this.availableTransitions && this.availableTransitions.some((v) => types.indexOf(v.type) >= 0);
  }

  isInEvents(...eventTypes) {
    return this.events && this.events.some((e) => eventTypes.indexOf(e.type) >= 0);
  }

  isFieldReadOnly(fieldName) {
    return this.fieldReadOnlyMap?.[fieldName] || false;
  }

  submitEvent = async (eventType, eventData) => {
    try {
      await LicensingProcessEvent.create({
        licensingProcess: `/licensingProcesses/${this.id}`,
        type: eventType,
        data: eventData,
      });
    } catch (error) {
      throw error;
    }
  };

  getRequestEvent() {
    const event = this.events.find((event) => event.type === 'REQUEST_TO_PRODUCE_SENT');
    event.attachment = this.attachments.find((a) => a.name.match(/Origem das Sementes\.(.*)/g));
    return event;
  }

  getRespondToRequestEvent() {
    const event = this.events.find(
      (event) => event.type === 'REQUEST_TO_PRODUCE_REJECTED' || event.type === 'REQUEST_TO_PRODUCE_APPROVED'
    );
    event.attachment = this.attachments.find((a) => a.name.match(/Origem das Sementes\.(.*)/g));
    return event;
  }

  getIssueContractEvent = () => {
    const event = this.events.find((event) => event.type === 'CONTRACT_ISSUED');
    event.attachment = this.getContractAttachment();
    return event;
  };

  getContractAttachment = () => {
    return this.attachments.find((a) => a.name.match(/Contrato\.(.*)/g));
  };

  getFieldRegistrationAuthorizationAttachment = () => {
    return this.attachments.find((a) => a.name.match(/Autorização para Inscrição de Campo\.(.*)/g));
  };

  getFieldRegistrationConfirmationAttachment = (createdAt) => {
    const reference = dayjs(createdAt.replace('+0000', 'Z'));
    const fieldAttachments = this.attachments
      .filter((a) => a.name.match(/Relação de Campos MAPA.*\.(.*)/g))
      .sort((a, b) => {
        return (
          Math.abs(dayjs(a.createdAt.replace('+0000', 'Z')).diff(reference)) -
          Math.abs(dayjs(b.createdAt.replace('+0000', 'Z')).diff(reference))
        );
      });
    if (fieldAttachments.length > 0) {
      return fieldAttachments[0];
    }
    return null;
  };

  getRespondToContractEvent = () => {
    const event = this.events.find((event) => event.type === 'CONTRACT_REJECTED' || event.type === 'CONTRACT_SIGNED');
    event.attachment = this.getContractAttachment();
    return event;
  };

  getIssueFieldRegistrationAuthorizationEvent = () => {
    const event = this.events.find((event) => event.type === 'FIELD_REGISTRATION_AUTHORIZATION_ISSUED');
    event.attachment = this.getFieldRegistrationAuthorizationAttachment();
    return event;
  };

  getSendFieldRegistrationConfirmationEvents = () => {
    return this.events
      .filter((event) => event.type === 'FIELD_REGISTRATION_CONFIRMATION_SENT')
      .map((event) => ({
        ...event,
        attachment: this.getFieldRegistrationConfirmationAttachment(event.createdAt),
      }));
  };

  getLatestYieldInformationUpdateEvent = () => {
    const events = this.events.filter((event) => event.type === 'YIELD_INFORMATION_UPDATED');
    if (events.length > 0) {
      return events[events.length - 1];
    }
    return undefined;
  };

  rejectRequest = async () => {
    try {
      await this.submitEvent('REQUEST_TO_PRODUCE_REJECTED');
    } catch (error) {
      throw error;
    }
  };

  approveRequest = async () => {
    try {
      await this.submitEvent('REQUEST_TO_PRODUCE_APPROVED');
    } catch (error) {
      throw error;
    }
  };

  issueContract = async () => {
    try {
      await this.submitEvent('CONTRACT_ISSUED');
    } catch (error) {
      throw error;
    }
  };

  signContract = async () => {
    try {
      await this.submitEvent('CONTRACT_SIGNED');
    } catch (error) {
      throw error;
    }
  };

  rejectContract = async () => {
    try {
      await this.submitEvent('CONTRACT_REJECTED');
    } catch (error) {
      throw error;
    }
  };

  issueFieldRegistrationAuthorization = async () => {
    try {
      await this.submitEvent('FIELD_REGISTRATION_AUTHORIZATION_ISSUED');
    } catch (error) {
      throw error;
    }
  };

  sendFieldRegistrationConfirmation = async (payload) => {
    try {
      await this.submitEvent('FIELD_REGISTRATION_CONFIRMATION_SENT', payload);
    } catch (error) {
      throw error;
    }
  };

  updateYieldInformation = async (payload) => {
    try {
      await this.submitEvent('YIELD_INFORMATION_UPDATED', payload);
    } catch (error) {
      throw error;
    }
  };

  postMessage = async (payload) => {
    try {
      await this.submitEvent('MESSAGE_POSTED', payload);
    } catch (error) {
      throw error;
    }
  };

  cancel = async (payload) => {
    try {
      await this.submitEvent('PROCESS_CANCELED', payload);
    } catch (error) {
      throw error;
    }
  };

  canIssueContract() {
    return this.isInAvailableTransitions('ISSUE_CONTRACT');
  }

  canRespondToContract() {
    return this.isInAvailableTransitions('REJECT_CONTRACT', 'SIGN_CONTRACT');
  }

  canSendFieldRegistrationConfirmation() {
    return this.isInAvailableTransitions('SEND_FIELD_REGISTRATION_CONFIRMATION');
  }

  canUpdateYieldInformation() {
    return this.isInAvailableTransitions('UPDATE_YIELD_INFORMATION');
  }

  canIssueFieldRegistrationAuthorization() {
    return this.isInAvailableTransitions('ISSUE_FIELD_REGISTRATION_AUTHORIZATION');
  }

  canRespondToRequest() {
    return this.isInAvailableTransitions('REJECT_REQUEST_TO_PRODUCE', 'APPROVE_REQUEST_TO_PRODUCE');
  }

  isBeyondRequestPhase() {
    return (
      this.isInEvents('REQUEST_TO_PRODUCE_SENT') &&
      this.isInEvents('REQUEST_TO_PRODUCE_REJECTED', 'REQUEST_TO_PRODUCE_APPROVED')
    );
  }

  isBeyondContractPhase() {
    return this.isInEvents('CONTRACT_ISSUED') && this.isInEvents('CONTRACT_REJECTED', 'CONTRACT_SIGNED');
  }

  isBeyondFieldsPhase() {
    return (
      this.isInEvents('FIELD_REGISTRATION_AUTHORIZATION_ISSUED') &&
      this.isInEvents('FIELD_REGISTRATION_CONFIRMATION_SENT')
    );
  }

  isBeyondYieldPhase() {
    return this.isInEvents('YIELD_INFORMATION_UPDATED');
  }

  isInRequestPhaseOrBeyond() {
    return this.canRespondToRequest() || this.isBeyondRequestPhase();
  }

  isInContractPhaseOrBeyond() {
    return this.canIssueContract() || this.canRespondToContract() || this.isBeyondContractPhase();
  }

  isInFieldsPhaseOrBeyond() {
    return (
      this.canIssueFieldRegistrationAuthorization() ||
      this.canSendFieldRegistrationConfirmation() ||
      this.isBeyondFieldsPhase()
    );
  }

  isInYieldPhaseOrBeyond() {
    return this.canUpdateYieldInformation() || this.isBeyondYieldPhase();
  }

  isPastSeason() {
    var year = parseInt(this.seasonYear.id.split('/')[0], 10);
    return new Date().getFullYear() - year >= 2;
  }
}
