import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

export default function TermsAndConditions({ open, onAgree, onDisagree }) {
  return (
    <Dialog open={open} onClose={onDisagree} scroll="paper" aria-labelledby="scroll-dialog-title">
      <DialogTitle id="scroll-dialog-title">Termos e Condições</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <p>
            Bem-vindo a NetSeeds! NetSeeds é um portal baseado na nuvem (o “Serviço”) que fornece capacidades de
            gerenciamento do processo de licenciamento de cultivares protegidas. Entretanto, antes de entrar no site da
            NetSeeds ou usar o Serviço, é preciso rever cuidadosamente os Termos e condições definidos abaixo (os
            "Termos e condições"). Nestes Termos e condições, “Você” se refere ao Titular da conta e aos Usuários
            permitidos, e esses dois termos são definidos abaixo. Estes Termos e condições podem ser alterados ou
            atualizados a qualquer momento, mas Você sempre pode encontrar a versão mais recente em
            https://www.netseeds.com.br. No caso de inconsistência entre estes Termos e condições e a informação
            incluída nos materiais físicos (por exemplo, correspondências e materiais promocionais), estes Termos e
            condições irão prevalecer. Verifique esta página periodicamente para estar sempre atualizado.
          </p>
          <p>
            Ao entrar e usar o site, Você indica que aceita estes Termos e condições e que concorda em estar vinculado a
            eles. A aceitação destes Termos e condições cria um contrato vinculatório entre Você e a NetSeeds através do
            qual Você irá usar o Serviço apenas de forma consistente com estes Termos e condições. Caso tenha alguma
            dúvida sobre estes Termos e condições, entre em contato através do e-mail contato@netseeds.com.br. O seu uso
            dos Serviços está totalmente condicionado e sujeito a sua conformidade com estes Termos e condições. Caso
            não concorde com estes Termos e condições, não acesse nem use o Serviço.
          </p>

          <h4>Acordo para uso do contrato eletronicamente</h4>
          <p>
            O seu uso do Serviço significa que Você concorda com o contrato com a NetSeeds eletronicamente. Isso
            significa que ao clicar no botão para aceitar estes Termos e condições, Você concorda com todos os termos
            deste acordo com a intenção de entrar em um contrato com a NetSeeds. Além disso, a NetSeeds pode se
            comunicar com Você por e-mail ou publicando avisos em www.netseeds.com.br (“Site”). Você concorda que esses
            avisos e outras comunicações que a NetSeeds fornece a Você eletronicamente atendem a qualquer requisito
            legal de que essas comunicações sejam feitas por escrito. Você representa que Você tem, no mínimo, 18 anos
            de idade e que tem a idade exigida para assinar legalmente um contrato em sua jurisdição e, caso esteja
            assinando o Serviço em nome de um empregador, empresa ou outra entidade legal, Você representa que está
            devidamente autorizado a assinar um contrato vinculatório em nome de tal entidade.
          </p>

          <h4>Modificações destes Termos e Condições</h4>
          <p>
            A NetSeeds, segundo seu critério exclusivo, se reserva o direito de alterar os termos destes Termos e
            condições a qualquer momento. A NetSeeds irá publicar os Termos e condições atualizados no Site e irá
            alterar a data “Última atualização” acima para refletir a data da alteração, portanto, verifique estes
            Termos e condições regularmente. Caso não aceite os Termos e condições modificados, não use o Serviço, e o
            seu único recurso será parar de usar o Serviço. Ao continuar utilizando o Serviço depois que essas
            alterações forem publicadas, Você aceita os Termos e condições modificados. As obrigações da NetSeeds com
            respeito ao Serviço são regidas exclusivamente por estes Termos e condições, e nada que altere ou aumente as
            obrigações da NetSeeds deve ser considerado, a menos que exigido por lei local. A NetSeeds e Suas obrigações
            com respeito ao Site são regidas por estes Termos e condições e pelo Site da web da NetSeeds - Termos de uso
            e restrições legais (“Termos do site da web da NetSeeds”) que podem ser exibidos ou para os quais pode haver
            um link a partir do Site. No caso de um conflito direto entre uma cláusula nestes Termos e condições e uma
            cláusula nos Termos do Site da web da NetSeeds, a cláusula destes Termos e condições deve prevalecer. Além
            disso, determinadas cláusulas destes Termos e Condições podem ser substituídas por avisos legais
            expressamente designados publicados no Site e, em tais circunstâncias, o aviso legal expressamente designado
            deve ser considerado como incorporado a estes Termos e condições e deve substituir a(s) cláusula(s) destes
            Termos e condições que for(em) designada(s) a ser(em) substituída(s).
          </p>

          <h4>O acesso ao Serviço requer uma conta e informações pessoais</h4>
          <p>
            Para usar o Serviço, Você precisará abrir uma conta no Serviço (“Conta”). Você deve ter, no mínimo, 18 anos
            de idade ou ter, de outra forma, a capacidade legal para assinar estes Termos e condições. Caso esteja
            assinando o Serviço em nome de um empregador, empresa ou outra entidade legal, é necessário estar
            devidamente autorizado a participar de um contrato vinculatório em nome dessa entidade. A pessoa ou entidade
            que assinar o Serviço será chamada de “Titular da conta”. NetSeeds está disponível a Você somente se Você
            for o Titular da conta ou se o Titular da conta tiver uma Conta ativa para o Serviço e Você for autorizado
            pelo Titular da conta a usar esse Serviço como parte da Conta. Os usuários autorizados serão chamados de
            “Usuários permitidos”. Nestes Termos e condições, “Você” se refere ao Titular da conta e aos Usuários
            permitidos.
          </p>

          <p>
            Você reconhece e concorda que o Seu acesso ao Serviço pode ser interrompido ou encerrado devido a uma
            suspensão, encerramento ou expiração do acordo do Titular da conta com a NetSeeds ou qualquer revendedor
            terceirizado autorizado do Serviço.
          </p>

          <p>
            Você é responsável por manter a confidencialidade da Sua senha de Conta de serviço e Você é o único
            responsável por todas as atividades que ocorrem por meio do uso de Sua senha. Você concorda em notificar a
            NetSeeds imediatamente de qualquer uso não autorizado de Sua senha ou qualquer outra violação de segurança
            relacionada ao Serviço. A NetSeeds se reserva o direito de exigir que Você altere Sua senha caso a NetSeeds
            acredite que ela não é mais segura.
          </p>

          <h4>Privacidade e registro no Serviço</h4>
          <p>
            Durante o processo de registro da Conta, a NetSeeds coleta informações pessoais sobre Você incluindo, mas
            sem se limitar a:
            <ul>
              <li>Nome completo do Titular da conta e dos Usuários permitidos</li>
              <li>Informações de contato do Titular da conta e dos Usuários permitidos</li>
            </ul>
          </p>

          <p>
            Seus dados pessoais e as informações do sistema do seu computador são usadas pela NetSeeds para gerenciar a
            Sua Conta (em que a NetSeeds pode atuar como um Processador de dados ou Controlador de dados), para fornecer
            os serviços solicitados a NetSeeds, administrar usuários e dispositivos, fornecer autenticação de usuário,
            melhorar o desempenho do Serviço, exercitar nossos direitos sob estes Termos e condições e fazer ofertas de
            marketing a Você de acordo com suas preferências de privacidade. Você concorda que a NetSeeds, suas
            subsidiárias e suas afiliadas coletem e usem informações técnicas provenientes de seu uso dos Serviços.
            Sempre que o Serviço estiver conectado à Internet, o endereço IP aplicável ou outro identificador único de
            dispositivo associado a seu dispositivo NetSeeds será enviado à NetSeeds para facilitar a coleta dessas
            informações técnicas. A coleta e o uso das informações coletadas deverão ser regidos pela Declaração de
            privacidade da NetSeeds.
          </p>

          <h4>Comunicação com o Titular da conta e Usuários permitidos</h4>
          <p>
            A NetSeeds se reserva o direito de se comunicar com Você por meio de e-mail para o propósito de administrar
            a conclusão da compra, períodos de teste para recursos adicionais ou serviços, faturamento, contratos,
            suporte, avisos sobre a segurança dos produtos, atualizações de driver ou outras notificações
            administrativas e transacionais, uma vez que a finalidade principal dessas comunicações não é promocional
            por natureza. Comunicações promocionais da NetSeeds serão enviadas de acordo com as preferências de contato
            do Titular da conta.
          </p>

          <h4>Requisitos do sistema</h4>
          <p>
            O uso do Serviço requer que Você atenda aos requisitos mínimos. Para os requisitos completos do sistema,
            consulte Requisitos do sistema. Você é responsável por obter e manter todos os equipamentos e serviços
            necessários para acessar e usar o Serviço e por pagar por todos os custos relacionados.
          </p>

          <h4>Segurança</h4>
          <p>
            A NetSeeds envidará esforços comercialmente razoáveis para implantar medidas de segurança razoáveis e
            apropriadas para ajudar o Titular da conta e os Usuários permitidos a proteger seu Conteúdo.
          </p>

          <p>
            O Titular da conta é responsável por administrar o acesso a Sua Conta, além de manter firewalls, métodos de
            autenticação e de criptografia que considerar adequados. O Titular da conta e os Usuários permitidos são
            responsáveis por proteger sua(s) senha(s). O Titular da conta é e permanece como o controlador dos dados do
            Conteúdo que fizer upload ou fornecer como parte do Serviço. A NetSeeds é uma prestadora de serviços e é
            apontada através deste, pelo titular da conta, como uma processadora de dados. A NetSeeds irá processar o
            Conteúdo apenas conforme o necessário para fornecer o Serviço ou de outra maneira instruída pelo Titular da
            conta. O conteúdo será hospedado em data centers da NetSeeds ou de seus terceirizados nos EUA e pode ser
            acessado pela NetSeeds ou por seus terceirizados em outros locais, conforme necessário para fornecer os
            Serviços ou para dar suporte a eles.
          </p>

          <h4>Manutenção programada e de emergência</h4>
          <p>
            A NetSeeds envidará esforços comercialmente razoáveis para programar todas as manutenções planejadas e
            atualizações de serviço durante os finais de semana, de 18h de sexta-feira até 18h de domingo, no horário da
            região central dos EUA. A NetSeeds pode mudar os horários da realização da manutenção programada, segundo
            seu critério exclusivo. Os Serviços e/ou Seu Conteúdo podem estar indisponíveis durante a manutenção
            programada. A NetSeeds se reserva o direito de realizar serviços de manutenção de emergência a qualquer
            momento e sem aviso prévio. O Serviço como um todo ou uma porção dele pode estar indisponível durante
            qualquer período da manutenção de emergência.
          </p>

          <h4>Conduta do usuário</h4>
          <p>
            Você concorda em cumprir com todas as leis, ordens, regras, regulamentações requisitos aplicáveis impostos
            pelos governos e agências reguladoras com relação ao Seu uso do Serviço, incluindo, mas sem se limitar ao
            Seu uso, transmissão e divulgação de qualquer Conteúdo ou informação por meio do Serviço. Você não pode (i)
            reproduzir, duplicar, copiar, vender, revender ou explorar nenhuma parte do Serviço, nem (ii) usar ou
            acessar para propósitos comerciais nenhuma versão do Serviço que não seja designada como uma versão
            comercial.
          </p>

          <p>
            Os usuários do Serviço não podem usá-lo para processar, armazenar ou disseminar Conteúdos proibidos.
            Geralmente, os Conteúdos proibidos incluem material que a NetSeeds acredita que:
            <ul>
              <li>Viole a lei;</li>
              <li>
                Seja abusivo, depreciativo, pornográfico, obsceno, difamatório, caluniador, ofensivo ou inapropriado de
                alguma outra forma;
              </li>
              <li>Compreenda material com direitos autorais utilizado sem a permissão expressa do proprietário;</li>
              <li>Viole ou infrinja de outra forma os direitos de outros;</li>
              <li>
                Contenha vírus, worms, arquivos corrompidos, cavalos de troia ou outras formas de código corruptor que
                possa comprometer o Serviço (coletivamente “Código corruptor”);
              </li>
              <li>Advogue ou induza a atividades ilegais;</li>
              <li>
                Use algum tipo de meio de alto volume automatizado (incluindo robôs, aranhas ou scripts) para acessar o
                Serviço ou quaisquer outras contas, sistemas ou redes conectadas ao Serviço;
              </li>
              <li>
                Tente obter acesso não autorizado a qualquer porção do Serviço ou a qualquer conta, sistema ou rede
                conectada ao Serviço;
              </li>
              <li>
                Interfira, interrompa ou viole a segurança ou integridade do Serviço ou qualquer conta, sistema ou rede
                conectada ao Serviço, incluindo hacking, desestabilização ou adaptação do Serviço, ou alteração de outro
                site da web para implicar falsamente se tratar de um site afiliado ao Serviço;
              </li>
              <li>
                Distribua, publique, envie ou facilite e-mails em massa não solicitados, promoções, anúncios ou
                aliciamentos, incluindo publicidade comercial e anúncios informativos;
              </li>
              <li>Persiga, assedie ou prejudique qualquer pessoa, inclusive menores; ou,</li>
              <li>Forneça um link a conteúdo que se enquadre em qualquer uma das situações descritas acima.</li>
            </ul>
          </p>

          <p>
            Entre Você e a NetSeeds, Você será o único responsável por todo e qualquer Conteúdo que Você enviar,
            publicar, armazenar, distribuir, promover, transmitir ou gerar/criar um link para/do Serviço. Ao enviar
            Conteúdo por meio do Serviço, Você representa que é o proprietário do Conteúdo, ou que está enviando com o
            consentimento expresso do proprietário. A NetSeeds não será responsável, de nenhuma forma, (sob as leis de
            direito autoral, difamação, privacidade, obscenidade ou qualquer outra) por nenhum Conteúdo fornecido a Você
            ou outras pessoas através deste Serviço. A NetSeeds se reserva o direito (mas não assume nenhuma obrigação)
            de excluir, mover ou editar qualquer Conteúdo que chegue à nossa atenção e que consideremos inaceitável ou
            inapropriado, seja por motivos legais ou não. A NetSeeds também se reserva o direito de determinar se o
            Conteúdo fornecido a Você através do Serviço e o Seu uso do Serviço são apropriados e estão de acordo com
            estes Termos e condições, e, sem aviso a Você e segundo nosso critério exclusivo, podemos remover o Conteúdo
            a qualquer momento e podemos suspender ou encerrar o Seu acesso, caso acreditemos que esse Conteúdo ou o Seu
            uso do Serviço viole estes Termos e condições ou seja, de outra forma, inapropriado.
          </p>

          <p>
            A NetSeeds pode relatar qualquer atividade que suspeite violar qualquer lei ou regulamentação aos agentes de
            aplicação da lei, reguladores ou outros terceiros apropriados. A NetSeeds também pode cooperar com terceiros
            para ajudar na investigação e acusação de conduta ilegal fornecendo informações de rede e de sistemas,
            relativas a violações alegadas a estes Termos e condições. O relato ou cooperação da NetSeeds pode incluir a
            revelação de Conteúdo relevante ou outras informações relacionadas à Conta.
          </p>

          <h4>Obrigações do Titular da conta</h4>
          <p>
            O titular da conta cumprirá com cada uma das seguintes obrigações: (i) cumprir e garantir que os Usuários
            permitidos cumpram com todas as leis aplicáveis, regras e regulamentações incluindo as relativas à
            privacidade dos dados, direitos autorais e controle de exportação, e com estes Termos e condições; (ii)
            cumprir com as limitações de uso aplicáveis ao Serviço adquirido pelo Titular da conta; (iii) pagar as taxas
            do Serviço dentro do vencimento; (iv) usar precauções de segurança razoáveis para fornecer acesso ao Serviço
            por seus funcionários e outros indivíduos a quem o titular da conta e Usuário permitido fornecer acesso; (v)
            cooperar com investigação razoável da NetSeeds sobre falhas, problemas de segurança e qualquer suspeita de
            violação destes Termos e condições; (vi) cumprir com todos os termos de qualquer software, conteúdo, serviço
            ou site da web (incluindo o Conteúdo) que o Titular da conta e Usuário permitido usar ou acessar ao usar o
            Serviço, seja disponibilizado ao Titular da conta e Usuário permitido por meio do Serviço ou de um terceiro;
            (vii) dar à NetSeeds e sua revendedora autorizada (se for o caso) informações reais, precisas, atuais e
            completas (“Informações de conta”) ao estabelecer a Conta para o Serviço, e manter contato de faturamento e
            outras informações de Conta atualizadas; (viii) ser responsável pelo uso do Serviço pelo Titular da conta e
            Usuários permitidos do Titular da conta; e (ix) notificar imediatamente a NetSeeds sobre qualquer uso não
            autorizado conhecido ou suspeito da Conta, do Serviço ou outra violação de segurança. O Titular da conta
            será o único responsável por adquirir e manter as conexões de rede que conectam sua rede aos sistemas da
            NetSeeds. O Titular da conta e o Usuário permitido representam e garantem que o Conteúdo não deve violar nem
            infringir nenhum direito de propriedade intelectual de nenhum terceiro. O Titular da conta, o Usuário
            permitido e seus usuários fornecem e concordam em fornecer à NetSeeds, sob todos os direitos de propriedade
            intelectual do Conteúdo, uma licença não exclusiva, irrevogável, internacional, livre de royalties e
            totalmente paga para usar, importar, distribuir, modificar e distribuir modificações, realizar, criar e
            distribuir trabalhos derivados, copiar e exibir Conteúdo, exclusivamente em conexão com o fornecimento do
            Serviço ao Titular da conta e Usuários permitidos. Essa licença inclui o direito da NetSeeds sublicenciar
            aos subsidiários e afiliados e qualquer terceiro, fornecendo todo ou parte do Serviço em nome da NetSeeds.
          </p>

          <h4>Propriedade e licenças</h4>
          <p>
            Entre Você e a NetSeeds, a NetSeeds detém todos os direitos do Serviço, incluindo, mas sem se limitar a
            todos os direitos de propriedade intelectual, exceto seus direitos ao seu Conteúdo. Você fornece e concorda
            em fornecer à NetSeeds, através deste, exclusivamente até onde for necessário para a NetSeeds fornecer o
            Serviço (incluindo Serviços de suporte) a Você, sob todos os direitos de propriedade intelectual embutidos
            no Seu Conteúdo, uma licença não exclusiva, perpétua, irrevogável, internacional, livre de royalties e
            totalmente paga por usar, importar, distribuir, modificar e distribuir modificações, realizar, criar e
            distribuir trabalhos derivativos, além de copiar e exibir o Seu Conteúdo. A NetSeeds se reserva todos os
            direitos não expressamente fornecidos a Você por meio deste. “Opinião” refere-se a toda e qualquer sugestão,
            melhoria ou outra opinião sobre os Serviços que qualquer um forneça à NetSeeds, seja direta ou
            indiretamente. A NetSeeds será titular de quaisquer direitos e títulos relativos às Opiniões, incluindo, mas
            sem se limitar a todos os direitos de propriedade intelectual delas. Você atribui à NetSeeds através deste,
            e de maneira irrevogável, todos os direitos e títulos das Opiniões, e concorda em fornecer à NetSeeds toda a
            assistência que a NetSeeds possa solicitar para documentar, aperfeiçoar e manter os direitos da NetSeeds
            sobre as Opiniões.
          </p>

          <h4>Suspensão ou rescisão dos serviços</h4>
          <p>
            A NetSeeds pode suspender o fornecimento dos Serviços sem nenhuma responsabilidade caso a NetSeeds acredite
            razoavelmente que: (i) os Serviços estão sendo usados (ou foram usados ou serão usados) violando estes
            Termos e condições; (ii) o Titular da conta e Usuário permitido não estão cooperando com a investigação da
            NetSeeds de qualquer suspeita de violação destes Termos e condições; (iii) o Serviço fornecido ao Titular da
            conta e Usuário permitido foi acessado ou manipulado por um terceiro sem o consentimento do Titular da conta
            e Usuário permitido ou violando estes Termos e condições; (iv) a suspensão do Serviço é necessária para
            proteger a rede da NetSeeds, os outros clientes da NetSeeds ou outras pessoas em geral; (v) um pagamento do
            Serviço está vencido; (vi) a continuação do uso dos Serviços por Você pode prejudicar o Serviço ou os
            sistemas ou conteúdo da NetSeeds ou quaisquer outros clientes da NetSeeds; (vii) o uso do Serviço por Você
            pode expor a NetSeeds, suas afiliadas ou qualquer terceiro a responsabilizações jurídicas; ou (viii) a
            suspensão seja exigida por lei. A NetSeeds dará ao Titular da conta e Usuário permitido um aviso antecipado
            razoável sobre uma suspensão e dará a chance de resolver as questões nas quais a suspensão é baseada, a não
            ser que a NetSeeds determine que seja necessária, segundo julgamento comercial razoável da NetSeeds, a
            suspensão, com aviso prévio, ou nenhuma antecedência ou até mesmo a suspensão sem aviso. As taxas do Serviço
            podem continuar a ser cobradas do Titular da conta e Usuário permitido durante a suspensão, incluindo uma
            taxa de retorno razoável na retomada dos Serviços, que deve ser cobrada segundo o critério exclusivo da
            NetSeeds.
          </p>

          <p>
            A NetSeeds pode rescindir a assinatura e/ou Conta por conveniência a qualquer momento através de aviso por
            escrito com 30 (trinta) dias de antecedência. A NetSeeds também pode rescindir uma assinatura e/ou Conta:
            (i) por conta de informações incompletas ou materialmente imprecisas fornecidas pelo Titular da conta e
            Usuário permitido sobre seu uso proposto do Serviço; (ii) se o Titular da conta for uma entidade ou
            fiduciário, se o indivíduo que solicitou o Serviço para o Titular da conta e qualquer Usuário permitido não
            tinha o direito legal ou a autoridade para entrar nestes Termos e condições em nome da pessoa representada
            como o Titular da conta, ou se o Titular da conta e o Usuário permitido seja um indivíduo que não tinha, no
            mínimo, 18 anos de idade ou, de outra maneira, não tinha a capacidade legal para celebrar estes Termos e
            condições no momento em que o Serviço foi solicitado; (iii) se pagamentos de qualquer quantia do Titular da
            conta pelo Serviço estiverem vencidos; (iv) se o Serviço for utilizado violando estes Termos e condições e
            se a violação não for resolvida em até 30 (trinta) dias após aviso por escrito da NetSeeds; (v) se com o uso
            do Serviço violando estes Termos e condições, segundo o julgamento comercial razoável da NetSeeds, a
            rescisão for necessária para proteger a NetSeeds, seus outros clientes ou qualquer terceiro contra riscos
            operacionais, de segurança ou outros; (v) se a Conta estiver suspensa há 30 (trinta) dias ou mais; ou (vi)
            se não for possível cumprir com nenhuma outra cláusula destes Termos e condições, e se essa falha não for
            resolvida (se houver solução possível) diante de um aviso razoável da NetSeeds. A NetSeeds também pode
            rescindir a Conta se o Titular da conta se tornar alvo de uma petição por falência ou qualquer outro
            procedimento relativo à falência, concordata, liquidação ou cessão em benefício dos credores. A NetSeeds
            pode, segundo seu critério exclusivo, rescindir a Sua Conta (ou qualquer parte dela) ou o Seu uso do
            Serviço, e remover e descartar qualquer Conteúdo, a qualquer momento, sem aviso, por um bom motivo,
            incluindo: (i) conduta que viole estes Termos e condições ou outras políticas ou diretrizes definidas pela
            NetSeeds em outro lugar do Serviço, ou (ii) conduta que a NetSeeds acredite ser prejudicial a outros
            usuários da NetSeeds, aos negócios da NetSeeds ou a afiliadas da NetSeeds. A NetSeeds não será responsável
            por Você nem por nenhuma outra parte por conta de qualquer rescisão do seu acesso ao Serviço. Segundo
            critério exclusivo da NetSeeds, o Titular da conta e o Usuário permitido podem não ter acesso ao Conteúdo
            armazenado no Serviço durante uma suspensão e a NetSeeds não deve ser responsabilizada por danos ou perdas
            resultantes dessa suspensão e/ou falta de acesso ao Conteúdo durante uma suspensão ou consequente rescisão.
            Até onde for permitido pelas leis locais (incluindo leis de proteção ao consumidor) a NetSeeds não deve ser
            responsável pelo Titular da conta e Usuário permitido ou qualquer usuário por quaisquer danos ou perdas
            decorrentes de não ter acesso ao Conteúdo. A NetSeeds pode incluir, remover ou alterar, segundo seu próprio
            critério, os serviços oferecidos através do Serviço. A NetSeeds se reserva o direito de descontinuar o
            Serviço, a qualquer momento, mediante aviso por escrito com uma antecedência razoável.
          </p>

          <h4>Isenção de responsabilidade por materiais e sites da web de terceiros</h4>
          <p>
            Determinados conteúdos, aplicativos, serviços, produtos e outros materiais disponíveis por meio do Serviço e
            do Site são de terceiros ou podem incluir materiais de terceiros. Você concorda que a NetSeeds não é
            responsável por nenhum desses conteúdos, aplicativos, serviços, produtos ou outros materiais de terceiros
            (“materiais de terceiros”). A NetSeeds não garante nem endossa e não terá nenhuma responsabilidade por
            materiais de terceiros. O Seu uso de materiais de terceiros é totalmente de Sua responsabilidade e pode
            estar sujeito a condições e termos adicionais.
          </p>

          <p>
            Links do Serviço e do Site a sites da web de terceiros são fornecidos exclusivamente como uma conveniência
            para Você. Se Você usar esses links, Você sairá do Site. A NetSeeds não controla e não é responsável por
            tais sites da web de terceiros (ou os produtos, serviços, conteúdo ou outros materiais disponibilizados
            através deles) e não endossa nem faz representações sobre tais sites da web de terceiros e os produtos,
            serviços, conteúdo ou outros materiais fornecidos através deles. A Declaração de privacidade on-line da
            NetSeeds não tem efeito nesses sites da web de terceiros e a NetSeeds não é responsável pelo uso de Suas
            informações por parte dos sites da web de terceiros. Caso decida acessar qualquer um dos sites da web de
            terceiros com link no Serviço ou Site, Você fará isso por sua própria conta e risco e estará sujeito a
            condições e termos adicionais.
          </p>

          <h4>Indenização</h4>
          <p>
            Se a NetSeeds, os funcionários da NetSeeds, agentes, afiliadas, subsidiárias ou fornecedores (os
            “Indenizados da NetSeeds”) se depararem com uma reclamação jurídica feita por um terceiro por conta do
            Conteúdo e/ou uso do Serviço pelo Titular da conta ou seus Usuários permitidos (“Reclamação de terceiros”),
            o Titular da conta irá pagar o custo da defesa contra a Reclamação de terceiros (incluindo os honorários
            advocatícios condizentes) e quaisquer danos, premiações, multas, acordos ou outras quantias (“Perdas”) que
            sejam impostas aos Indenizados da NetSeeds como resultado da Reclamação de terceiros. O Titular da conta irá
            manter os Indenizados da NetSeeds protegidos contra toda e qualquer Perda imposta aos Indenizados da
            NetSeeds como resultado da Reclamação de terceiros. As obrigações do Titular da conta sobre esta seção
            incluem reclamações decorrentes dos atos ou omissões do Titular da conta e seus Usuários Permitidos,
            funcionários, ou qualquer outra pessoa a quem o Titular da conta tiver dado acesso ao Serviço e/ou Conteúdo,
            e qualquer pessoa que obtenha acesso ao Serviço e/ou Conteúdo como resultado de falha do Titular da conta ou
            Usuário permitido em usar precauções razoáveis de segurança, até mesmo se os atos ou omissões dessas pessoas
            não tiverem sido autorizados pelo Titular da conta ou seus Usuários permitidos. Em conexão com quaisquer
            Reclamações de terceiros relativas a esta Seção, a NetSeeds (i) dará ao Titular da conta um aviso por
            escrito imediato sobre tal reclamação; e (ii) irá cooperar de forma razoável com o Titular da conta (com as
            despesas de responsabilidade do Titular da conta) fornecendo informações em conexão com o pagamento do
            Titular da conta para a defesa contra tal reclamação e Perdas decorrentes de tal reclamação.
          </p>

          <h4>Garantia limitada</h4>
          <p>
            A NetSeeds garante que a NetSeeds assinou de maneira válida estes Termos e condições e tem o poder legal
            para fazê-lo. A NetSeeds irá usar os esforços comercialmente razoáveis para fornecer o Serviço diretamente
            ou através de um terceirizado. A NetSeeds não promete que o Serviço será ininterrupto, livre de erros ou
            completamente seguro. Você reconhece que existem riscos inerentes à conectividade com a Internet que podem
            resultar na perda da Sua privacidade, informações confidenciais, Conteúdo e/ou propriedade. A NetSeeds não
            tem obrigação de fornecer segurança que não seja a definida nestes Termos e condições. Você reconhece ainda
            que com respeito aos recursos de localização de dispositivo fornecidos como parte do Serviço, cada transação
            de determinação de localização está sujeita a fatores ambientais e outras variáveis, dependendo do horário,
            local, configuração e área para um usuário específico e, adequadamente, a NetSeeds não garante a precisão,
            acessibilidade ou disponibilidade dos serviços de localização de dispositivo, em todo ou em parte. Até onde
            for permitido pelas leis locais (incluindo leis de proteção ao consumidor), para qualquer violação da
            garantia da NetSeeds ou obrigações destes Termos e condições, a solução exclusiva do Titular da conta e do
            Usuário permitido é encerrar a Conta. ATÉ O MÁXIMO PERMITIDO PELA LEI, (INCLUINDO LEIS DE PROTEÇÃO AO
            CONSUMIDOR), A NetSeeds SE ISENTA DE TODA E QUALQUER GARANTIA, ESTABELECIDA POR LEI OU DE OUTRA FORMA, NÃO
            EXPRESSAMENTE DECLARADA NESTES TERMOS.
          </p>

          <h4>Lei vigente</h4>
          <p>
            Estes Termos e condições devem ser interpretados de acordo com, e governados pelas leis locais de onde Você
            reside.
          </p>

          <h4>Modificação dos Termos e condições</h4>
          <p>
            A NetSeeds pode incluir emendas a estes Termos e condições a qualquer momento, por um bom motivo, de tempos
            em tempos. Quaisquer emendas ou modificações feitas pela NetSeeds serão apenas prospectivas.
          </p>

          <h4>Contrato completo</h4>
          <p>
            Estes Termos e condições constituem o contrato completo entre Você e a NetSeeds governando o Seu uso do
            Serviço. No caso de inconsistências entre estes Termos e condições e qualquer informação incluída nos
            materiais off-line (por exemplo, materiais promocionais e malas diretas), estes Termos e condições devem
            prevalecer sempre. Você pode estar sujeito também a termos e condições adicionais que podem se aplicar
            quando Você usar software de terceiros.
          </p>

          <p>
            A falha da NetSeeds em cumprir ou impor qualquer termo destes Termos e condições não constituirá uma
            renúncia a esse termo. Se algum termo for considerado inválido por algum tribunal de jurisdição competente,
            as partes concordam que esse tribunal deve esforçar-se para dar efeito às intenções das partes conforme se
            reflita na cláusula, e as outras cláusulas destes Termos e condições irão permanecer em pleno efeito e
            vigência.
          </p>

          <p>
            Independentemente de qualquer estatuto ou lei definindo o contrário, qualquer reclamação ou causa de ação
            que Você tenha em decorrência ou em relação ao Serviço ou a estes Termos e condições deverá ser feita em um
            ano após o surgimento da reclamação ou causa de ação, ou irá prescrever.
          </p>

          <h4>Denúncia de violações</h4>
          <p>Você pode denunciar o uso abusivo destes Termos e condições para contato@netseeds.com.br</p>

          <p>© 2016 NetSeeds. Revisão: 28 de junho de 2016</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree}>Não estou de acordo</Button>
        <Button onClick={onAgree}>Estou de acordo</Button>
      </DialogActions>
    </Dialog>
  );
}
