import React from 'react';
import Typography from '@material-ui/core/Typography';
import EventMessage from '../../components/EventMessage';
import FormattedDate from '../../components/FormattedDate';

export const HistoryContent = ({ process, user }) => (
  <React.Fragment>
    {process.events.map((event) => (
      <React.Fragment key={event.createdAt}>
        <Typography variant="caption" color="textSecondary">
          <FormattedDate value={event.createdAt} />
        </Typography>
        <Typography gutterBottom>
          <strong>{user.email === event.userId ? 'Você' : event.user}</strong>{' '}
          <EventMessage active event={event} includeDetails process={process} />
        </Typography>
      </React.Fragment>
    ))}
  </React.Fragment>
);
