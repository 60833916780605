import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Helmet } from 'react-helmet';
import {
  AlterarSenhaPage,
  CadastroPage,
  RedefinirSenhaPage,
  RecuperarSenhaPage,
  TutorialPage,
  CultivaresPage as Cultivares,
  EntrarPage,
  HomePage as Home,
  LicenciamentoPage as Licenciamento,
  Produtores,
} from './pages';
import RegistrationNagware from './RegistrationNagware';

import Sair from './Sair';
import AppBar from './components/AppBar';
import Auth from './auth';
import PrivateRoute from './PrivateRoute';
import { LoadingContext } from './LoadingContext';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.startLoading = () => {
      this.setState({ loading: true });
    };
    this.stopLoading = () => {
      this.setState({ loading: false });
    };

    this.state = {
      loading: false,
      user: Auth.user,
      startLoading: this.startLoading,
      stopLoading: this.stopLoading,
    };
  }

  componentDidMount() {
    Auth.subscribe(this.handleUserChange);
  }

  componentWillUnmount() {
    Auth.unsubscribe(this.handleUserChange);
  }

  handleUserChange = (user) => this.setState({ user });

  render() {
    const { loading, user } = this.state;
    const nav = <AppBar loading={loading} user={user} onLogOut={() => Auth.logout()} />;
    return (
      <React.Fragment>
        <Helmet>
          <title>NetSeeds</title>
        </Helmet>
        <CssBaseline />
        <LoadingContext.Provider value={this.state}>
          <Router>
            <Route path="/*" render={(props) => <RegistrationNagware {...props} />} />
            <Switch>
              <Route exact path="/" render={(props) => <Home nav={nav} {...props} />} />
              <PrivateRoute path="/alterar-senha" component={AlterarSenhaPage} />
              <Route path="/cadastro" exact component={CadastroPage} />
              <Route path="/cultivares" render={(props) => <Cultivares nav={nav} {...props} />} />
              <Route path="/entrar" component={EntrarPage} />{' '}
              <PrivateRoute path="/licenciamento" render={(props) => <Licenciamento nav={nav} {...props} />} />
              <PrivateRoute path="/produtores" render={(props) => <Produtores nav={nav} user={user} {...props} />} />
              <Route path="/redefinir-senha/:token" component={RedefinirSenhaPage} />
              <Route path="/recuperar-senha" component={RecuperarSenhaPage} />
              <Route path="/sair" component={Sair} />
              <Route path="/tutorial" component={TutorialPage} />
            </Switch>
          </Router>
        </LoadingContext.Provider>
      </React.Fragment>
    );
  }
}

export default App;
