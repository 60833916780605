import axios from 'axios';

const list = async () => {
  return await axios.get('/producerProfiles');
};

const mapBySeasonYear = async () => {
  return await axios.get('/producerProfiles', {
    params: { mapBy: 'seasonYear' },
  });
};

const get = async (id) => {
  const [producer, users, licensingProcesses] = await Promise.all([
    axios.get(`/producerProfiles/${id}`),
    axios.get(`/producerProfiles/${id}/users`),
    axios.get(`/producerProfiles/${id}/licensingProcesses`),
  ]);
  return {
    ...producer.data,
    users: [...users.data],
    licensingProcesses: [...licensingProcesses.data],
  };
};

const ProducerProfile = {
  list,
  mapBySeasonYear,
  get,
};

export default ProducerProfile;
