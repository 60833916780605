import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Auth from './auth';

export default function PrivateRoute({ component: Component, render, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (Auth.isAuthenticated()) {
          if (render) return render(props);
          return <Component {...props} />;
        }
        return (
          <Redirect
            to={{
              pathname: '/entrar',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}
