export default function styles(theme) {
  return {
    grow: {
      flexGrow: 1,
    },
    linearColorPrimary: {
      backgroundColor: theme.palette.primary.light,
    },
    linearBarColorPrimary: {
      backgroundColor: theme.palette.primary.dark,
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20,
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    toolbarRoot: {
      [theme.breakpoints.up('lg')]: {
        margin: 'auto',
        width: '1200px',
      },
    },
    toolbarGutters: {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    activeLink: {
      backgroundColor: theme.palette.primary.dark,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    button: {
      marginLeft: theme.spacing(1),
    },
  };
}
